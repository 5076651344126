import React, { useState, useEffect } from "react";
import { scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import {
  scaleTime,
  axisRight,
  event,
  interpolateHcl,
  range,
  timeMonths,
  timeMonth,
} from "d3";

function getTextColor(rgba) {
  rgba = rgba.match(/\d+/g);
  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
    return "black";
  } else {
    return "white";
  }
}

function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

const TITLE_PADDING = 65;
const LEGEND_OFFSET = 10;
const LEGEND_WIDTH = 20;

const ROW_LABEL_WIDTH = 210;
const tilewidth = 14;
const tileheight = 15;
const LEFT = 5;
const TOP = 5;

const hidelegend = true;

export const GenericHeatmap = React.memo((props: any) => {
  const [node, setNode] = useState();
  const [nodeRowLabels, setNodeRowLabels] = useState();
  const [nodeColLabels, setNodeColLabels] = useState();
  const [isReady, setIsReady] = useState(false);

  var {
    data,
    rowlabels,
    minv,
    maxv,
    title,
    tooltipprecision,
    mousedownhandler,
    scale,
    nogap,
    colLabel,
    redGreenColormap,
    zeroAsWhite,
    maxH,
  } = props;

  if (!colLabel) colLabel = "Week";

  // this effect draws the row loabels
  useEffect(() => {
    if (nodeRowLabels && data && data[0]) {
      (nodeRowLabels as any).innerHTML = "";
      var SCALE = 1;
      if (scale) {
        SCALE = scale;
      }

      var j;

      const numRows = data.length;

      var offset = -5;

      var rowLabels = [];

      for (j = 0; j < numRows; j++) {
        if (j % 3 === 0 && !nogap) {
          offset = offset + 5;
        }
        rowLabels[j] = {
          label: rowlabels[j],
          y: (TOP + j * tileheight + offset) * scale,
        };
      }

      // this is the div node that will contain all the d3 content
      var div = select(nodeRowLabels);

      var width = ROW_LABEL_WIDTH;

      // add the svg node
      var svg = div
        .append("svg")
        .attr("width", width)
        .attr(
          "height",
          rowlabels.length * tileheight * scale +
            (nogap ? 4 : rowlabels.length * 4)
        )
        .append("g")
        .attr("transform", "translate(" + 0 + "," + 0 + ")");

      svg
        .selectAll(".ylabel")
        .data(rowLabels)
        .enter()
        .append("text")
        .attr("x", 0)
        .attr("y", function (d) {
          return d.y;
        })
        .style("text-anchor", "end")
        .attr(
          "transform",
          "translate(" + 2 * SCALE + "," + SCALE * (tileheight / 1.5 + 1) + ")"
        )
        // .append("a")
        // .attr("xlink:href", function (d) { return "/project/" + d.id; })
        .attr("style", "fill: #0000aa; font-size: 12px;")
        .text(function (d) {
          return d.label;
        });
    }
  }, [data, data.length, nodeRowLabels, nogap, rowlabels, scale]);

  // this effect draws the column (date) labels
  useEffect(() => {
    if (nodeColLabels && data && data[0]) {
      (nodeColLabels as any).innerHTML = "";
      var SCALE = 1;
      if (scale) {
        SCALE = scale;
      }

      const numCols = data[0].length;

      const FLOWCELL_WIDTH = tilewidth * numCols + LEFT;

      // this is the div node that will contain all the d3 content
      var div = select(nodeColLabels);

      var width = FLOWCELL_WIDTH * SCALE;

      // add the svg node
      var svg = div
        .append("svg")
        .attr("width", width)
        .attr("height", TITLE_PADDING)
        .append("g")
        .attr("transform", "translate(" + 0 + "," + 0 + ")");

      var x1 = LEFT * SCALE;
      var x2 = x1 + numCols * tilewidth * scale;

      var startDate = new Date(2023, 0, 2, 0, 0, 0, 0);
      var endDate = new Date(2023, 0, 2, 0, 0, 0, 0);
      endDate.setDate(endDate.getDate() + 7 * numCols);

      console.log("start date", startDate);
      console.log("end date", endDate);
      console.log("num weeks", numCols);

      const monthDiff =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());

      const monthTicks = timeMonths(
        startDate,
        timeMonth.offset(startDate, monthDiff)
      );

      const xScale = scaleTime().domain([startDate, endDate]).range([x1, x2]);

      svg
        .selectAll("text")
        .data(monthTicks)
        .enter()
        .append("text")
        .attr("x", (d) => xScale(d))
        .attr("y", 38)
        .attr("text-anchor", "middle")
        .text((d) => d.toLocaleString("default", { month: "short" }));

      svg
        .selectAll("text.year")
        .data(monthTicks.filter((d) => d.getMonth() === 0))
        .enter()
        .append("text")
        .attr("x", (d) => xScale(d))
        .attr("y", 20)
        .attr("text-anchor", "middle")
        .text((d) => d.getFullYear());

      svg
        .append("line")
        .attr("x1", x1)
        .attr("y1", 48)
        .attr("x2", x2)
        .attr("y2", 48)
        .attr("stroke", "black");
    }
  }, [colLabel, data, nodeColLabels, scale]);

  useEffect(() => {
    if (node && data && data[0]) {
      (node as any).innerHTML = "";
      var SCALE = 1;
      if (scale) {
        SCALE = scale;
      }
      const TOOLTIP_OFFSET_X = { x: -250, y: -20 };
      const TOOLTIP_OFFSET_Y_FRAC = 0.1;
      const MIN_VALUE = minv;
      const MAX_VALUE = maxv;

      var i, j;

      const numRows = data.length;
      const numCols = data[0].length;

      const FLOWCELL_WIDTH = tilewidth * numCols + LEFT;
      const FLOWCELL_HEIGHT = 120;

      var rects = [];
      var cnt = 0;
      var offset = -5;

      var rowLabels = [];

      for (j = 0; j < numRows; j++) {
        if (j % 3 === 0 && !nogap) {
          offset = offset + 5;
        }
        rowLabels[j] = {
          label: rowlabels[j],
          y: (TOP + j * tileheight + offset) * scale,
        };

        var d = new Date(2023, 0, 2, 0, 0, 0, 0);

        for (i = 0; i < numCols; i++) {
          var label = data[j][i].label;
          var val = data[j][i].val;
          if (zeroAsWhite && val === 0) val = -1;
          rects[cnt++] = {
            x: LEFT + i * tilewidth,
            y: TOP + j * tileheight + offset,
            w: tilewidth,
            h: tileheight,
            v: val,
            l: label,
            ylabel: rowlabels[j],
            xlabel:
              colLabel +
              " " +
              (i + 1) +
              (colLabel === "Week" ? " " + getFormattedDate(d) : ""),
          };
          d.setDate(d.getDate() + 7);
        }
      }

      // this is the div node that will contain all the d3 content
      var div = select(node);

      // Add the DIV that will hold the tooltip to the DOM
      var tooltipDiv = div
        .append("div")
        .style("position", "absolute")
        .style("text-align", "center")
        .style("width", "80px")
        .style("height", mousedownhandler ? "16px" : "50px")
        .style("padding", "2px")
        .style("font", "10px sans-serif")
        .style("background", "white")
        .style(
          "box-shadow",
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        )
        //.style("border", "1px")
        .style("border-radius", "8px")
        .style("opacity", 0);

      var altcolors = [
        "#ffffd9",
        "#edf8b1",
        "#c7e9b4",
        "#7fcdbb",
        "#41b6c4",
        "#1d91c0",
        "#225ea8",
        "#253494",
        "#081d58",
      ];

      var altcolors2 = ["red", "yellow", "green"];

      var colors = redGreenColormap ? altcolors2 : altcolors;
      var colorRange = range(0, 1, 1.0 / (colors.length - 1));
      colorRange.push(1);

      //maps values to a value between 0 and 1
      // var scaleToValue = scaleLinear()
      //     .domain([MIN_VALUE, MAX_VALUE])
      //     .range([0,1]);

      //maps a value of 0 to 1 to a color
      var colorScale = scaleLinear()
        .domain(colorRange)
        .range(colors)
        .interpolate(interpolateHcl);

      var width = FLOWCELL_WIDTH * SCALE;

      // add the svg node
      var svg = div
        .append("svg")
        .attr("width", width)
        .attr(
          "height",
          rowlabels.length * tileheight * scale +
            (nogap ? 4 : rowlabels.length * 4)
        )
        .append("g")
        .attr("transform", "translate(" + 0 + "," + 0 + ")");

      // the tiles
      const tiles = svg.selectAll(".tiles").data(rects);

      tiles
        .enter()
        .append("rect")
        .attr("id", (d) => d.l)
        .attr("x", (d) => d.x * SCALE)
        .attr("y", (d) => d.y * SCALE)
        .attr("rx", 1)
        .attr("ry", 1)
        .attr("class", "mytile")
        .attr("width", (d) => d.w * SCALE)
        .attr("height", (d) => d.h * SCALE)
        .style("stroke", "gray")
        .style("stroke-width", 1)
        .style("fill", (d) => {
          var tmp = d.v;
          tmp = (tmp - MIN_VALUE) / (MAX_VALUE - MIN_VALUE);
          if (isNaN(tmp)) return "#CCCCCC";
          if (tmp > 1) return "purple";
          if (tmp < 0) {
            return "white";
          }
          return colorScale(tmp);
        });

      tiles
        .enter()
        .append("text")
        .attr("x", (d) => d.x * SCALE)
        .attr("y", (d) => d.y * SCALE - 5)
        .text(function (d) {
          return d.l;
        })
        .style("stroke", (d) => {
          var tmp = d.v;
          tmp = (tmp - MIN_VALUE) / (MAX_VALUE - MIN_VALUE);
          if (isNaN(tmp)) return "white";
          if (tmp > 1) return "white";
          if (tmp < 0) {
            return "black";
          }
          return getTextColor(colorScale(tmp));
        })
        .style("text-anchor", "middle")
        .style("user-select", "none")
        .attr("transform", "translate(14, 25)")
        .on("mouseover", (d) => {
          tooltipDiv.transition().duration(200).style("opacity", 1);
          var secondLine = "";
          if (!mousedownhandler) {
            secondLine = `${+d.v.toFixed(tooltipprecision).toString()}`;
          }
          tooltipDiv
            .html(d.xlabel + "<br/>" + d.ylabel + "<br/>" + secondLine)
            .style("left", event.pageX + TOOLTIP_OFFSET_X.x + "px")
            .style("top", event.pageY + TOOLTIP_OFFSET_X.y + "px");
        })
        .on("mouseout", (d) => {
          tooltipDiv.transition().duration(500).style("opacity", 0);
        });
      setIsReady(true);

      if (!hidelegend) {
        //Append a defs (for definition) element to your SVG
        var defs = svg.append("defs");

        const id = Math.random() * 100000;
        //Append a linearGradient element to the defs and give it a unique id
        var linearGradient = defs
          .append("linearGradient")
          .attr("id", "linear-gradient" + id)
          .attr("x1", "0%")
          .attr("x2", "0%")
          .attr("y1", "100%")
          .attr("y2", "0%");

        //Append multiple color stops by using D3's data/enter step
        linearGradient
          .selectAll("stop")
          .data(colorScale.range())
          .enter()
          .append("stop")
          .attr("offset", function (d, i) {
            return i / (colorScale.range().length - 1);
          })
          .attr("stop-color", function (d) {
            return d;
          });

        svg
          .append("rect")
          .attr("x", FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET)
          .attr("y", FLOWCELL_HEIGHT * SCALE * TOOLTIP_OFFSET_Y_FRAC)
          .attr("width", LEGEND_WIDTH)
          .attr(
            "height",
            FLOWCELL_HEIGHT * SCALE * (1 - 2 * TOOLTIP_OFFSET_Y_FRAC)
          )
          .style("fill", "url(#linear-gradient" + id + ")");

        //Color Legend container
        var legendsvg = svg
          .append("g")
          .attr("class", "legendWrapper")
          .attr(
            "transform",
            "translate(" +
              (FLOWCELL_WIDTH * SCALE + LEGEND_OFFSET + LEGEND_WIDTH) +
              "," +
              FLOWCELL_HEIGHT * SCALE * TOOLTIP_OFFSET_Y_FRAC +
              ")"
          );

        //Set scale legend
        var yScale = scaleLinear()
          .range([0, FLOWCELL_HEIGHT * SCALE * (1 - 2 * TOOLTIP_OFFSET_Y_FRAC)])
          .domain([MAX_VALUE, MIN_VALUE]);

        //Define legend axis
        var yAxis = axisRight()
          .ticks(5) //Set rough # of ticks
          .scale(yScale);

        //Set up legend axis
        legendsvg
          .append("g")
          .attr("class", "axis") //Assign "axis" class
          .call(yAxis);
      }
    }
  }, [
    colLabel,
    data,
    maxv,
    minv,
    mousedownhandler,
    node,
    nogap,
    redGreenColormap,
    rowlabels,
    scale,
    title,
    tooltipprecision,
    zeroAsWhite,
  ]);

  useEffect(() => {
    const container1 = node as any;
    const container2 = nodeRowLabels as any;
    const container3 = nodeColLabels as any;
    if (container1 && container2 && container3) {
      const handleScroll = (e) => {
        if (e.target === container1) {
          container2.scrollTop = container1.scrollTop;
          container3.scrollLeft = container1.scrollLeft;
        } else if (e.target === container2) {
          container1.scrollTop = container2.scrollTop;
        } else {
          container1.scrollLeft = container3.scrollLeft;
        }
      };

      container1.addEventListener("scroll", handleScroll);
      container2.addEventListener("scroll", handleScroll);
      container3.addEventListener("scroll", handleScroll);

      return () => {
        container1.removeEventListener("scroll", handleScroll);
        container2.removeEventListener("scroll", handleScroll);
        container3.removeEventListener("scroll", handleScroll);
      };
    }
    return () => {};
  }, [node, nodeRowLabels, nodeColLabels]);

  //style={{ overflow: "scroll" }}
  useEffect(() => {
    const wait = (time) => {
      return new Promise((res) => setTimeout(res, time));
    };

    if (isReady) {
      wait(1000).then(() => {
        const container1 = node as any;
        const container3 = nodeColLabels as any;
        container1.scrollLeft = container1.scrollWidth;
        container3.scrollLeft = container3.scrollWidth;
      });
    }
  }, [isReady, node, nodeColLabels]);

  const maxHeight = maxH || "600px";
  return (
    <>
      <div
        style={{
          fontWeight: 500,
          fontSize: "2em",
          color: "#555555",
          paddingTop: 40,
          marginBottom: 0,
        }}
      >
        {title}
      </div>
      <div
        style={{
          height: TITLE_PADDING,
          width: "90%",
          display: "flex",
          overflow: "hidden",
          marginBottom: -15,
        }}
      >
        <div style={{ width: ROW_LABEL_WIDTH - 20, height: "100%" }} />
        <div
          style={{
            overflowX: "auto",
            overflowY: "hidden",
          }}
          ref={(node) => setNodeColLabels(node as any)}
        />
      </div>
      <div
        style={{
          maxHeight: maxHeight,
          width: "90%",
          display: "flex",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: ROW_LABEL_WIDTH,
            overflowX: "hidden",
            overflowY: "scroll",
            maxHeight: `calc(${maxHeight} - 17px)`,
            marginRight: -15,
          }}
          ref={(node) => setNodeRowLabels(node as any)}
        />
        <div
          style={{ overflow: "scroll" }}
          ref={(node) => setNode(node as any)}
        />
      </div>
    </>
  );
});
