import { useState, useEffect, useCallback } from "react";
import RunList from "./components/run-list";
import RunContainer from "./components/run-container";
import Resizable from "devextreme-react/resizable";
import "../sizable.css";
import { Button } from "devextreme-react";

export default () => {
  const [state, setState] = useState({
    run: null,
    tab: "",
  });
  const [tableVisible, setTableVisible] = useState(true);
  const [graphVisible, setGraphVisible] = useState(true);

  useEffect(() => {
    if (state.run && state.run.runID)
      window.history.pushState(
        null,
        null,
        `#/runs?r=${state.run.runID}&t=${state.tab}`
      );
  }, [state]);

  const setRunSelected = useCallback((run) => {
    setState((v) => ({ ...v, run }));
  }, []);
  return (
    <div style={{ display: "flex" }}>
      {graphVisible && (
        <Button
          icon="menu"
          stylingMode="text"
          onClick={() => setTableVisible((v) => !v)}
        />
      )}
      <div className="wrap">
        <Resizable
          className="resizable resizable1"
          style={{
            display: !tableVisible && (graphVisible ? "none" : undefined),
            width: !graphVisible && (tableVisible ? "100%" : undefined),
          }}
          autoHide
          onResize={({ element, width }) => {
            var remainingSpace = element.parentNode.offsetWidth - width,
              divTwoWidth =
                (remainingSpace / element.parentNode.offsetWidth) * 100 + "%";
            element.nextElementSibling.style.width = divTwoWidth;
          }}
        >
          <RunList runSelected={setRunSelected} />
        </Resizable>
        <div
          className="resizable resizable2"
          style={{
            width: !tableVisible && (graphVisible ? "100%" : undefined),
            display: !graphVisible && (tableVisible ? "none" : undefined),
          }}
        >
          <RunContainer
            selectedRun={state.run}
            tabSelected={(tab) => setState((v) => ({ ...v, tab }))}
            typeRun="Sequencing"
          />
        </div>
      </div>

      {tableVisible && (
        <Button
          icon="menu"
          stylingMode="text"
          onClick={() => setGraphVisible((v) => !v)}
        />
      )}
    </div>
  );
};
