import { RunEvent, TypeRun } from "../types";

export const getLinesFromRunEvents = (
  runEvents: RunEvent[],
  showCycleLines: boolean,
  typeRun: TypeRun
) => {
  const lines = [];

  for (let i = 0; i < runEvents.length; i++) {
    if (
      [
        "SequencingRunInitialized",
        "RunInitialized",
        "Priming",
        "Primed",
        "Sequencing",
        "Spatial",
        "ReadStarted",
        showCycleLines && "CycleStarted",
        //       "TrapStarted",
        // "ImagingStarted",
        // "ImagingFinished",
        //        "CleaveStarted",
        //        "IncorpStarted",
        //        "CycleFinished",
        "ReadFinished",
        "WashStarted",
        "WashFinished",
        "RecipeUpload_Completed",
        "Finished",
      ].indexOf(runEvents[i].name) >= 0
    )
      lines.push({
        key: `event${i}`,
        value: new Date(runEvents[i].timestamp),
        label:
          runEvents[i].name === "CycleStarted"
            ? `${
                typeRun === "Spatial" ? runEvents[i].batch : runEvents[i].read
              } C${runEvents[i].cycle}`
            : runEvents[i].name,
      });
  }
  return lines;
};
