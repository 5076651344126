import { memo, useState, useEffect, useRef, useCallback } from "react";
import { Button } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  Series,
  Legend,
  ValueAxis,
  Point,
  ArgumentAxis,
  Tooltip,
  ZoomAndPan,
  Label,
  Title,
} from "devextreme-react/chart";

type RunIndexingProps = {
  runID: string;
};

const RunIndexing = memo(({ runID }: RunIndexingProps) => {
  const chart = useRef();
  const containerDiv = useRef();
  const [data, setData] = useState([]);

  const {
    data: rawData,
    isLoading,
    refetch,
  } = useFetch(`/run/${runID}/indexing`, !!runID);

  useEffect(() => {
    let vals = [];
    if (rawData && rawData.SampleIndex) {
      for (let i = 0; i < rawData.SampleIndex.length; i++) {
        const v = rawData.SampleIndex[i];
        vals.push({
          SampleName: v.SampleName,
          PerfectMatch: v.PerfectMatchRate * 100,
          PartialMatch: v.PartialMatchRate * 100,
        });
      }
    }
    setData(vals);
  }, [rawData]);

  useEffect(() => {
    if (chart.current && data)
      (chart.current as any).instance.resetVisualRange();
  }, [chart, data]);

  useEffect(() => {
    chart.current && (chart.current as any).instance.render();
  }, []);

  const renderTooltip = useCallback((pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      return (
        <div>
          <span>{data.SampleName}</span>
          <br />
          <span>Perfect Match: {data.PerfectMatch.toPrecision(2)}%</span>
          <br />
          <span>Partial Match: {data.PartialMatch.toPrecision(2)}%</span>
          <br />
        </div>
      );
    }
    console.log(pointInfo);
    return <div />;
  }, []);

  console.log(rawData);

  return (
    <div ref={containerDiv} style={{ marginTop: 40 }}>
      <LoadPanel
        position={{ of: "#chart" }}
        visible={isLoading}
        showIndicator={true}
        showPane={true}
      />

      <Chart id="chart" ref={chart} height="80vh" dataSource={data}>
        <Title
          text={`Percent Assigned: ${(
            100 -
            rawData?.RateUnassigned * 100
          ).toFixed(2)}%`}
        />
        <CommonSeriesSettings argumentField="SampleName" type="stackedBar">
          <Point size={4} />
        </CommonSeriesSettings>

        <Series valueField="PartialMatch" name="Partial Match" color="orange" />
        <Series valueField="PerfectMatch" name="Perfect Match" color="yellow" />

        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis title="" />
        <ArgumentAxis title="Sample">
          <Label rotationAngle={90} displayMode="rotate" />
        </ArgumentAxis>
        <Legend
          visible={true}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />
      </Chart>
      <Button
        style={{ marginTop: 5 }}
        text="Refresh"
        icon="refresh"
        onClick={() => refetch()}
      />
    </div>
  );
});

export default RunIndexing;
