import React, { useEffect, useState, useRef, useCallback } from "react";

import RangeSelector, {
  Margin,
  Scale,
  MinorTick,
  Label,
  SliderMarker,
} from "devextreme-react/range-selector";
import { LoadPanel } from "devextreme-react/load-panel";
import { CheckBox } from "devextreme-react/check-box";
import Button from "devextreme-react/button";
import { Slider } from "devextreme-react/slider";
import { useAuth } from "src/contexts/auth";
import { fetchWithToken } from "src/hooks/useFetch";

var viewportW;
var viewportH;
var data,
  w = 0,
  h = 0;
var _zoomFactor = 1;
var _dx = 0;
var _dy = 0;
var minv = 20;
var maxv = 200;
var loaded = false;
var autoZoom = true;
var autoContrast = true;

var _panning = false;
var _DXmousedown = 0;
var _DYmousedown = 0;
var _mouseOrigPosition = { x: -1, y: -1 };

function format(value) {
  return `${value.toPrecision(2)}`;
}

const ImageToBitmap = (xImage, yImage) => {
  const xBitmap = (xImage - _dx) * _zoomFactor;
  const yBitmap = (yImage - _dy) * _zoomFactor;
  return { x: xBitmap, y: yBitmap };
};

const BitmapToImage = (xBitmap, yBitmap) => {
  const xImage = Math.floor(_dx + xBitmap / _zoomFactor + 0.5);
  const yImage = Math.floor(_dy + yBitmap / _zoomFactor + 0.5);
  return { x: xImage, y: yImage };
};

const CenterOn = (xf, yf) => {
  _dx = 0;
  _dy = 0;
  var pt = ImageToBitmap(xf, yf);

  pt = BitmapToImage(pt.x - viewportW / 2, pt.y - viewportH / 2);

  _dx = pt.x;
  _dy = pt.y;
};

const redraw = () => {
  var canvas = document.getElementById("myCanvas") as any;
  var ctx = canvas.getContext("2d");
  var imgData = ctx.createImageData(viewportW, viewportH);
  var i, j, offset, val, pt, idx;

  for (j = 0; j < viewportH; j++) {
    offset = j * viewportW * 4;
    for (i = 0; i < viewportW * 4; i += 4) {
      pt = BitmapToImage(i / 4, j);
      idx = pt.y * w + pt.x;
      if (pt.x < 0 || pt.x >= w || pt.y < 0 || pt.y >= h) {
        imgData.data[offset + i + 0] = 10;
        imgData.data[offset + i + 1] = 60;
        imgData.data[offset + i + 2] = 100;
        imgData.data[offset + i + 3] = 255;
      } else {
        val = data[idx];
        val = val - minv;
        val = (255 * val) / (maxv - minv);
        if (val > 255) val = 255;
        if (val < 0) val = 0;
        val = Math.floor(val);
        imgData.data[offset + i + 0] = val;
        imgData.data[offset + i + 1] = val;
        imgData.data[offset + i + 2] = val;
        imgData.data[offset + i + 3] = 255;
      }
    }
  }
  ctx.putImageData(imgData, 0, 0);
};

const doAutoContrast = () => {
  var min = 0;
  var max = 1;
  if (w < 100 || h < 10) return { min: min, max: max };
  const numLines = 256;
  const tmpData = new Uint16Array(w * numLines);
  const cy = h / 2;
  const offset = (cy - numLines / 2) * w;
  for (var i = 0; i < w * numLines; i++) tmpData[i] = data[offset + i];

  tmpData.sort();

  min = tmpData[Math.floor(tmpData.length / 10)];
  max = tmpData[Math.floor((tmpData.length * 999) / 1000)];
  if (max < min + 5) max = min + 5;

  minv = min;
  maxv = max;
  return { min: min, max: max };
};

const zoomAtLocation = (x, y, newZoom) => {
  var origZoom = _zoomFactor;
  _dx = _dx + x * (1.0 / origZoom - 1.0 / newZoom);
  _dy = _dy + y * (1.0 / origZoom - 1.0 / newZoom);
  _zoomFactor = newZoom;
  redraw();
};

const onMouseMove = (e) => {
  if (_panning) {
    var dx = e.offsetX - _mouseOrigPosition.x;
    var dy = e.offsetY - _mouseOrigPosition.y;
    _dx = _DXmousedown - dx / _zoomFactor;
    _dy = _DYmousedown - dy / _zoomFactor;
    redraw();
  }
};

const onMouseDown = (e) => {
  _mouseOrigPosition.x = e.offsetX;
  _mouseOrigPosition.y = e.offsetY;
  _DXmousedown = _dx;
  _DYmousedown = _dy;
  _panning = true;
};

const onMouseUp = (e) => {
  _panning = false;
};

type GrayscaleImageProps = {
  imageURL: string;
};

export const GrayscaleImage = React.memo(
  ({ imageURL }: GrayscaleImageProps) => {
    const [doneLoadingImage, setDoneLoadingImage] = useState(false);
    const [maxValue, setMaxValue] = useState(100);
    const rangeSelectorRef = useRef(null);
    const sliderRef = useRef(null);

    const { isLoading, getAccessTokenSilently } = useAuth();

    viewportW = 900;
    viewportH = 600;

    const ZoomOut = useCallback(() => {
      var zoomFactorX = viewportW / w;
      var zoomFactorY = viewportH / h;

      _zoomFactor = zoomFactorX < zoomFactorY ? zoomFactorX : zoomFactorY;
      CenterOn(w / 2, h / 2);
      sliderRef.current.instance.option("value", _zoomFactor);
    }, [sliderRef]);

    useEffect(() => {
      if (doneLoadingImage && data) {
        var i;
        var tmin = 100000;
        var tmax = 10;
        for (i = 0; i < data.length; i++) {
          if (data[i] > tmax) tmax = data[i];
          if (data[i] < tmin) tmin = data[i];
        }

        if (autoContrast) doAutoContrast();
        if (autoZoom) ZoomOut();
        rangeSelectorRef.current.instance.option("value", [minv, maxv]);
        setMaxValue(tmax);
        redraw();
      }
    }, [doneLoadingImage, ZoomOut]);

    const onMouseWheel = useCallback(
      (e) => {
        e.preventDefault();
        console.log(e);
        const x = e.offsetX;
        const y = e.offsetY;
        var delta = e.deltaY;
        var newZoom = _zoomFactor;
        if (delta < 0) newZoom = _zoomFactor * 1.2;
        else newZoom = _zoomFactor / 1.2;
        if (
          Math.floor(viewportH / newZoom) <= 1 ||
          Math.floor(viewportW / newZoom) <= 1
        ) {
          return;
        }
        zoomAtLocation(x, y, newZoom);
        sliderRef.current.instance.option("value", _zoomFactor);
      },
      [sliderRef]
    );

    useEffect(() => {
      const expand = (packedData) => {
        console.log("Expanding");
        w = (packedData[0] << 8) | packedData[1];
        h = (packedData[2] << 8) | packedData[3];

        data = new Uint16Array(w * h);

        var frompos = 4;
        const len = w * h;

        var topos = 0;
        for (var i = 0; i < len; i++) {
          data[topos++] = packedData[frompos++];
        }

        console.log("Done");

        var canvas = document.getElementById("myCanvas");
        canvas.addEventListener("mousemove", onMouseMove, false);
        canvas.addEventListener("mousedown", onMouseDown, false);
        canvas.addEventListener("mouseup", onMouseUp, false);
        canvas.addEventListener("wheel", onMouseWheel, false);

        loaded = true;
      };

      const callAPI = async () => {
        try {
          const blob = await fetchWithToken(
            getAccessTokenSilently,
            "/run/thumbnails8?url=" + imageURL
          );
          const buffer = await blob.arrayBuffer();
          const view = new Uint8Array(buffer);
          if (view[0] === 0 && view[1] === 0) {
            alert("Could not find " + imageURL);
            data = new Uint16Array(0);
            setDoneLoadingImage(true);
            return;
          }
          expand(view);
        } catch (error) {
          console.log(error);
          alert("An error has occurred:" + error);
        }
        setDoneLoadingImage(true);
      };

      setDoneLoadingImage(false);
      if (
        !isLoading &&
        imageURL &&
        imageURL.startsWith("s3") &&
        imageURL.indexOf("Undefined") === -1
      ) {
        callAPI();
      } else {
        data = new Uint16Array(0);
        setDoneLoadingImage(true);
      }

      return () => {
        var canvas = document.getElementById("myCanvas");
        if (canvas) {
          canvas.removeEventListener("mousemove", onMouseMove);
          canvas.removeEventListener("mousedown", onMouseDown);
          canvas.removeEventListener("mouseup", onMouseUp);
          canvas.removeEventListener("wheel", onMouseWheel);
        }
      };
    }, [isLoading, getAccessTokenSilently, imageURL, onMouseWheel]);

    //style={{ overflow: "scroll" }}

    var disp = "inline-block";
    //if (!doneLoadingImage) disp = "none"

    return (
      <div>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#myCanvas" }}
          visible={!doneLoadingImage && imageURL && imageURL.startsWith("s3")}
          showIndicator={true}
          shading={true}
        />

        <div style={{ display: disp }}>
          <div style={{ marginBottom: 20 }}>
            <CheckBox
              style={{ marginLeft: "20px" }}
              defaultValue={autoZoom}
              onValueChanged={(e) => {
                autoZoom = e.value;
                redraw();
              }}
              text="Auto Zoom Out"
            />
            <CheckBox
              style={{ marginLeft: "20px" }}
              defaultValue={autoContrast}
              onValueChanged={(e) => {
                autoContrast = e.value;
                redraw();
              }}
              text="Auto Contrast"
            />{" "}
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                ZoomOut();
                redraw();
              }}
              text="Zoom Out"
            />
            <Button
              style={{ marginLeft: "20px" }}
              onClick={() => {
                doAutoContrast();
                rangeSelectorRef.current.instance.option("value", [minv, maxv]);
                redraw();
              }}
              text="Auto Contrast"
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ width: "50%" }}>
              <span>Contrast:</span>
              <RangeSelector
                id="range-selector"
                ref={rangeSelectorRef}
                height={80}
                behavior={{
                  animationEnabled: false,
                  callValueChanged: "onMoving",
                  allowSlidersSwap: false,
                }}
                onValueChanged={(obj) => {
                  if (loaded) {
                    minv = obj.value[0] as any;
                    maxv = obj.value[1] as any;
                    redraw();
                  }
                }}
              >
                <Margin top={5} bottom={0} />
                <Scale startValue={0} endValue={maxValue} tickInterval={100}>
                  <MinorTick visible={false} />
                  <Label format="decimal" />
                </Scale>
                <SliderMarker format="decimal" />
              </RangeSelector>
            </div>
            <div style={{ width: "50%" }}>
              <span>Zoom:</span>
              <Slider
                ref={sliderRef}
                min={0.1}
                max={8}
                step={0.1}
                defaultValue={1}
                tooltip={{
                  enabled: true,
                  showMode: "always",
                  position: "bottom",
                  format,
                }}
                onValueChanged={(e) => {
                  zoomAtLocation(viewportW / 2, viewportH / 2, e.value);
                }}
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <em>{imageURL}</em>
          <br />
          <canvas id="myCanvas" width={viewportW} height={viewportH}></canvas>
        </div>
      </div>
    );
  }
);
