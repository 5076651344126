import React, {
  memo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { SelectBox, Button } from "devextreme-react";
import { Run } from "../types";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

import {
  Chart,
  CommonSeriesSettings,
  ConstantLine,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Label,
  ArgumentAxis,
  Tooltip,
  ZoomAndPan,
} from "devextreme-react/chart";

function exportTextFile(fileName, content) {
  // Create a Blob with the text content
  const blob = new Blob([content], { type: "text/plain" });

  // Create a link element
  const link = document.createElement("a");

  // Set the download attribute and create a link to the Blob
  link.download = fileName;
  link.href = window.URL.createObjectURL(blob);

  // Append the link to the document
  document.body.appendChild(link);

  // Trigger a click on the link to start the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
}

type RunQualityProps = {
  run: Run;
};

const q30Range = [0, 100];
const errorRange = [0, 20];
const displayItems = ["Q30", "Q40", "Q50", "Q15", "AvgQ", "Error"];

type BaseComposition = {
  PercentUnknown: number;
  PercentA: number;
  PercentC: number;
  PercentG: number;
  PercentT: number;
};

type CycleMetrics = {
  Read: string;
  Cycle: number;
  ErrorRate: number;
  AverageQScore: number;
  PercentQ15: number;
  PercentQ30: number;
  PercentQ40: number;
  PercentQ50: number;
  BaseComposition: BaseComposition;
  Label: string;
};

function getTextFromData(data: CycleMetrics[]): string {
  let txt = "";

  txt += "Read,Cycle,ErrorRate,AverageQScore,Q15,Q30,Q40\n";
  data.forEach((v) => {
    txt += `${v.Read},${v.Label.substring(1)},${v.ErrorRate},${
      v.AverageQScore
    },${v.PercentQ15},${v.PercentQ30},${v.PercentQ40}\n`;
  });
  return txt;
}

const RunQuality = memo(({ run }: RunQualityProps) => {
  const chart = useRef();
  const [display, setDisplay] = useState<
    "Error" | "Q15" | "Q30" | "Q40" | "Q50" | "AvgQ"
  >("Q30");
  const [libraryPoolDisplay, setLibraryPoolDisplay] = useState<
    "Run Level" | "Library Pool 1" | "Library Pool 2"
  >("Run Level");

  const [avgLine, setAvgLine] = useState(null);
  const containerDiv = useRef();

  const [data, setData] = useState<CycleMetrics[]>([]);

  const {
    data: runMetricsData,
    isLoading,
    refetch,
  } = useFetch(`/run/${run?.runID}/runMetrics`, !!run?.runID);

  console.log(runMetricsData);

  const cycleMetrics: CycleMetrics[] =
    libraryPoolDisplay === "Run Level"
      ? runMetricsData?.RunMetrics.CycleMetrics
      : libraryPoolDisplay === "Library Pool 1"
      ? runMetricsData?.LaneMetrics[0]?.CycleMetrics
      : runMetricsData?.LaneMetrics[1]?.CycleMetrics;

  useEffect(() => {
    if (cycleMetrics) {
      const justReads = cycleMetrics.map((x) => x.Read);
      const tmp = [...Array.from(new Set<string>(justReads))];

      let { readOrder } = run;

      if (readOrder.indexOf(",") === -1 && readOrder.length === 8) {
        readOrder =
          readOrder.substring(0, 2) +
          "," +
          readOrder.substring(2, 4) +
          "," +
          readOrder.substring(4, 6) +
          "," +
          readOrder.substring(6);
      }
      const sortOrder = readOrder.split(",");
      let distinctReads = [];
      for (var j = 0; j < sortOrder.length; j++) {
        if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
      }
      var maxCycleForRead = {};
      for (j = 0; j < distinctReads.length; j++) {
        maxCycleForRead[distinctReads[j]] = 0;
      }

      for (j = 0; j < cycleMetrics.length; j++) {
        if (cycleMetrics[j].Cycle > maxCycleForRead[cycleMetrics[j].Read]) {
          maxCycleForRead[cycleMetrics[j].Read] = cycleMetrics[j].Cycle;
        }
      }
      var readCycleOffset = {};
      readCycleOffset[distinctReads[0]] = 0;
      var currentOffset = 0;
      for (j = 0; j < distinctReads.length; j++) {
        readCycleOffset[distinctReads[j]] = currentOffset;
        currentOffset += maxCycleForRead[distinctReads[j]];
      }

      const values: CycleMetrics[] = [];
      for (i = 0; i < cycleMetrics.length; i++) {
        values.push({ ...cycleMetrics[i] });
      }

      for (var i = 0; i < cycleMetrics.length; i++) {
        values[i].Label = `${values[i].Read} C${values[i].Cycle}`;
        values[i].Cycle += readCycleOffset[values[i].Read];
      }

      for (i = 0; i < values.length; i++) {
        if (isNaN(values[i].Cycle)) values[i].Cycle = i + 1; // to compensate for apparent bug with P1 data
        if (values[i].ErrorRate === -999) values[i].ErrorRate = NaN;
        if (values[i].PercentQ15 === -999) values[i].PercentQ15 = NaN;
        if (values[i].PercentQ30 === -999) values[i].PercentQ30 = NaN;
        if (values[i].PercentQ40 === -999) values[i].PercentQ40 = NaN;
        if (values[i].PercentQ50 === -999) values[i].PercentQ50 = NaN;
        if (values[i].AverageQScore === -999) values[i].AverageQScore = NaN;
      }
      setData(values);
    } else {
      setData([]);
    }
  }, [cycleMetrics, run]);

  useEffect(() => {
    if (chart.current && data)
      (chart.current as any).instance.resetVisualRange();
  }, [chart, data]);

  useEffect(() => {
    chart.current && (chart.current as any).instance.render();
  }, []);

  useLayoutEffect(() => {
    let constLineColor = "gold";
    let constLineAvg = 0;
    let lines = [];

    if (
      data &&
      data.length > 0 &&
      ["Q15", "Q40", "Q30", "Q50", "Error", "AvgQ"].indexOf(display) >= 0
    ) {
      const allSeries = (chart.current as any).instance.getAllSeries();

      for (let i = 0; i < allSeries.length; i++) {
        const series = allSeries[i];
        // if (i > 3) series.hide();
        constLineColor = series.getColor();
        const pts = series.getAllPoints();
        let avg = 0.0;
        for (let i = 0; i < pts.length; i++) {
          avg = avg + pts[i].value;
        }
        constLineAvg = avg / pts.length;

        lines.push(
          <ConstantLine
            width={1}
            key={`constantLine${i}`}
            value={constLineAvg}
            color={constLineColor}
            dashStyle="dash"
          >
            <Label
              font={{ color: constLineColor }}
              text={constLineAvg.toPrecision(4)}
            />
          </ConstantLine>
        );
      }
    }
    setAvgLine(lines);
  }, [data, display]);

  const renderTooltip = useCallback(
    (pointInfo) => {
      if (pointInfo && pointInfo.point && pointInfo.point.data) {
        const data = pointInfo.point.data;
        const label3 = data.Label;
        return (
          <div>
            {label3}
            <br />
            {display}: {pointInfo.value.toPrecision(4)}
          </div>
        );
      }
      return <div />;
    },
    [display]
  );

  return (
    <div ref={containerDiv} style={{ marginTop: 40 }}>
      <LoadPanel
        position={{ of: "#chart" }}
        visible={isLoading}
        showIndicator={true}
        showPane={true}
      />

      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={displayItems}
              value={display}
              onValueChanged={(e) => {
                setDisplay(e.value);
              }}
            />
          </td>
        </tr>
      </table>

      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={["Run Level", "Library Pool 1", "Library Pool 2"]}
              value={libraryPoolDisplay}
              onValueChanged={(e) => {
                setLibraryPoolDisplay(e.value);
              }}
            />
          </td>
        </tr>
      </table>

      <Chart
        id="chart"
        ref={chart}
        height="70vh"
        onLegendClick={useCallback((e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }, [])}
        dataSource={data}
      >
        <CommonSeriesSettings
          argumentField="Cycle"
          valueField={useMemo(() => {
            switch (display) {
              case "Q15":
                return "PercentQ15";
              case "Q40":
                return "PercentQ40";
              case "Q50":
                return "PercentQ50";
              case "Q30":
                return "PercentQ30";
              case "Error":
                return "ErrorRate";
              case "AvgQ":
                return "AverageQScore";
              default:
                return display;
            }
          }, [display])}
          type="line"
        >
          <Point size={4} />
        </CommonSeriesSettings>
        <SeriesTemplate nameField="Read" />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis
          title={display.includes("Av") ? "Q Score" : display + " (%)"}
          wholeRange={
            display === "Q15" ||
            display === "Q30" ||
            display === "Q40" ||
            display === "Q50"
              ? q30Range
              : display === "Error"
              ? errorRange
              : null
          }
        >
          {avgLine}
        </ValueAxis>
        <ArgumentAxis title="Cycle" allowDecimals={true} />
        <Legend
          visible={true}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />
      </Chart>
      {display === "Error" && (
        <div>
          {runMetricsData?.ReadMetrics?.map((v) => (
            <div key={v.Read}>
              PhiX Alignment Percent {v.Read}:{" "}
              {v.RunPhixAlignmentRate?.toPrecision(3)}%
            </div>
          ))}
        </div>
      )}
      <Button
        style={{ marginTop: 20 }}
        text="Refresh"
        icon="refresh"
        onClick={() => {
          refetch();
        }}
      />

      <Button
        style={{ marginTop: 20, marginLeft: 16 }}
        text="Export"
        icon="export"
        onClick={() => {
          const txt = getTextFromData(data);
          exportTextFile(run.runName + ".csv", txt);
        }}
      />
    </div>
  );
});

export default RunQuality;
