import {
  Chart,
  CommonSeriesSettings,
  Export,
  Legend,
  ValueAxis,
  Grid,
  Series,
  ArgumentAxis,
  ZoomAndPan,
  Size,
  Tooltip,
  Format,
} from "devextreme-react/chart";
import { memo } from "react";

const legendClick = (e) => {
  const series = e.target;
  if (series.isVisible()) {
    series.hide();
  } else {
    series.show();
  }
};

const computeMovingAvg = (array, index) => {
  if (isNaN(array[index].rate)) return NaN;

  let sum = 0;
  let cnt = 0;
  if (index >= 2 && !isNaN(array[index - 2].rate)) {
    sum += array[index - 2].rate;
    cnt++;
  }

  if (index >= 1 && !isNaN(array[index - 1].rate)) {
    sum += array[index - 1].rate;
    cnt++;
  }

  if (!isNaN(array[index].rate)) {
    sum += array[index].rate;
    cnt++;
  }

  if (cnt === 0) return NaN;

  return sum / cnt;
};

const getData = (totalData, successField) => {
  const tmp = [];

  for (let i = 0; i < totalData.length; i++) {
    const val = { ...totalData[i] };
    val.rate = val.runs === 0 ? NaN : (100 * val[successField]) / val.runs;
    tmp.push(val);
  }

  for (let i = 0; i < tmp.length; i++) {
    tmp[i].movingAvg = computeMovingAvg(tmp, i);
  }

  return tmp;
};

const PFChart = memo(
  ({ totalData, title, failedField, successField, argAxisLabel }) => {
    const plotData = getData(totalData, successField);

    return (
      <>
        <div
          style={{
            fontWeight: 500,
            fontSize: "2em",
            color: "#555555",
            paddingTop: 40,
            marginBottom: -30,
          }}
        >
          {title}
        </div>
        <Chart id="chart" onLegendClick={legendClick} dataSource={plotData}>
          <Size width="100%" height={400} />
          <Tooltip enabled>
            <Format type="fixedPoint" precision={1} />
          </Tooltip>

          <CommonSeriesSettings argumentField="week" type="stackedbar" />
          <Series
            valueField={failedField}
            name="Failed"
            axis="runs"
            color="red"
          />
          <Series
            valueField={successField}
            name="Succeeded"
            axis="runs"
            color="green"
          />

          <Series
            valueField="rate"
            name="% Success"
            axis="percentage"
            type="line"
            color="#666666"
          />

          <Series
            valueField="movingAvg"
            name="Trend"
            axis="percentage"
            type="line"
            color="#FF44FF"
            dashStyle="dash"
            point={{ size: 2 }}
          />
          <ValueAxis name="runs" position="left" title="# of Runs">
            <Grid visible={true} />
          </ValueAxis>
          <ValueAxis
            name="percentage"
            position="right"
            title="%"
            visualRangeUpdateMode="keep"
            visualRange={[0, 100]}
          >
            <Grid visible={true} />
          </ValueAxis>
          <ArgumentAxis title={argAxisLabel} allowDecimals={false} />
          <Legend
            visible
            position="outside"
            horizontalAlignment="center"
            verticalAlignment="bottom"
          />
          <ZoomAndPan argumentAxis="both" />
          <Export enabled={true} />
        </Chart>
      </>
    );
  }
);

export default PFChart;
