import { Auth0Provider } from "@auth0/auth0-react";
import { useAuth0 as useAuth } from "@auth0/auth0-react";

function AuthProvider(props) {
  return (
    <Auth0Provider
      domain="elembio.auth0.com"
      clientId="co9RAeuQMPUdESqHlBiumHvLElxOwAg0"
      redirectUri={window.location.origin}
      audience="https://telemetry.elembio.io"
    >
      {props.children}
    </Auth0Provider>
  );
}

export { useAuth, AuthProvider };
