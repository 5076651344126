import React, { FC, memo, useState, useEffect } from "react";
import { Button, CheckBox, SelectBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

import { FlowcellChart } from "../../../components";
import { ColorRangeBar } from "../../../components";

interface PolonyDensityProps {
  runID: string;
}

interface DensityReturnType {
  tileLabels: string[];
  runRawPolonyCountInM: number;
  runPFPolonyCountInM: number;
  tileRawDensityInKpmm2: number[];
  tilePFDensityInKpmm2: number[];
}

const metricsLevels = [ "Run Level", "Library Pool 1", "Library Pool 2" ];


const PolonyDensity: FC<PolonyDensityProps> = memo(
  ({ runID }: PolonyDensityProps) => {
    const [libraryPoolDisplay, setLibraryPoolDisplay] = useState<0 | 1 | 2 >(0);
    const [range, setRange] = useState({ min: 0, max: 100 });
    const [defaultRange, setDefaultRange] = useState(null);
    const [showPF, setShowPF] = useState(false);
    const [fixScale, setFixScale] = useState(false);
    const [forceRefresh, setForceRefresh] = useState(false);
    const {
      data: flowCellData,
      isLoading,
      refetch,
    } = useFetch(
      `/run/${runID}/density/${libraryPoolDisplay}` + (forceRefresh ? "?forceRefresh" : ""),
      !!runID
    );

    useEffect(() => {
      if (fixScale) {
        return;
      }
      console.log(flowCellData);
      if (flowCellData) {
        const fcd = flowCellData as DensityReturnType;
        let minv = 999999;
        let maxv = -999999;
        console.log("fcd", fcd);

        if (!fcd.tilePFDensityInKpmm2) {
          fcd.tilePFDensityInKpmm2 = [];
          for (let i = 0; i < fcd.tileLabels.length; i++)
            fcd.tilePFDensityInKpmm2.push(0);
        }

        if (!fcd.tileRawDensityInKpmm2) {
          fcd.tileRawDensityInKpmm2 = [];
          for (let i = 0; i < fcd.tileLabels.length; i++)
            fcd.tileRawDensityInKpmm2.push(0);
        }

        for (let i = 0; i < fcd.tileLabels.length; i++) {
          let value = 0;
          if (showPF) value = fcd.tilePFDensityInKpmm2[i];
          else value = fcd.tileRawDensityInKpmm2[i];
          if (value < minv) minv = value;
          if (value > maxv) maxv = value;
        }
        if (minv > maxv) {
          minv = 0;
          maxv = 100;
        } else if (minv === maxv) {
          minv = minv * 0.9;
          maxv = maxv * 1.1;
        }
        setRange({ min: minv, max: maxv });
        setDefaultRange({ min: minv, max: maxv });
      }
    }, [flowCellData, showPF, fixScale]);

    return (
      <div style={{ marginTop: 30 }}>
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#parentDiv" }}
          visible={isLoading}
          showIndicator={true}
          shading={true}
          // showPane={true}
        />

        <br />
        
        {flowCellData && (
          <>
            <table>
              <tr>
                <td>Display:</td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>
                  <SelectBox
                    items={metricsLevels}
                    value={metricsLevels[libraryPoolDisplay]}
                    onValueChanged={(e) => {
                      
                      setLibraryPoolDisplay(metricsLevels.indexOf(e.value) as (0 | 1 | 2));
                    }}
                  />
                </td>
              </tr>
            </table>
            <table>
              <tr>
                <td>{metricsLevels[libraryPoolDisplay]} Raw Polony Count</td>
                <td>&nbsp;</td>
                <td> {flowCellData.runRawPolonyCountInM}M</td>
              </tr>
              <tr>
                <td>{metricsLevels[libraryPoolDisplay]} PF Polony Count</td>
                <td>&nbsp;</td>
                <td> {flowCellData.runPFPolonyCountInM}M</td>
              </tr>
            </table>
          </>
        )}

        <div
          style={{
            textAlign: "center",
            width: "400px",
            paddingRight: "100px",
            marginTop: "30px",
          }}
        >
          <strong>Polony Density (k/mm2)</strong>
        </div>
        <div
          id="parentDiv"
          style={{
            marginTop: "5px",
            display: "flex",
            width: "400px",
            height: "500px",
          }}
        >
          {flowCellData && (
            <>
              <div style={{ width: "160px", height: "500px" }}>
                <FlowcellChart
                  data={
                    showPF
                      ? flowCellData.tilePFDensityInKpmm2
                      : flowCellData.tileRawDensityInKpmm2
                  }
                  labels={flowCellData.tileLabels}
                  minv={range.min}
                  maxv={range.max}
                  title="S1"
                  tooltipprecision={0}
                  hidelegend
                />
              </div>{" "}
              <div style={{ width: "160px", height: "500px" }}>
                <FlowcellChart
                  data={
                    showPF
                      ? flowCellData.tilePFDensityInKpmm2
                      : flowCellData.tileRawDensityInKpmm2
                  }
                  labels={flowCellData.tileLabels}
                  minv={range.min}
                  maxv={range.max}
                  title="S2"
                  tooltipprecision={0}
                  hidelegend
                />
              </div>
              <div
                style={{ width: "100px", display: defaultRange ? "" : "none" }}
              >
                <ColorRangeBar
                  minv={defaultRange?.min}
                  maxv={defaultRange?.max}
                  rangeChanged={(r) => setRange(r)}
                />
              </div>
            </>
          )}
        </div>

        <Button
          style={{ marginTop: 5 }}
          text="Refresh from Snowflake"
          icon="refresh"
          onClick={() => {
            setForceRefresh(true);
            refetch();
          }}
        />

        <CheckBox
          text="PF"
          style={{ marginLeft: "20px" }}
          value={showPF}
          onValueChanged={(e) => {
            setShowPF(e.value);
          }}
        />
        <CheckBox
          text="Fix Scale"
          style={{ marginLeft: "20px" }}
          value={fixScale}
          onValueChanged={(e) => {
            setFixScale(e.value);
          }}
        />
      </div>
    );
  }
);

export default PolonyDensity;
