import React, { memo, useState, useEffect } from "react";
import { Button, CheckBox, NumberBox, SelectBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

import ColorRangeBar from "../../../components/color-range-bar/ColorRangeBar";
import FlowCellChart from "../../../components/flowcell-chart/flowcell-chart";

interface RunTileProps {
  runID: string;
}

interface MetricReturnType {
  tileLabels: string[];
  values: number[];
}

const metricList = [
  "TileQ15",
  "TileQ30",
  "TileErrorRate",
  "TileCCP90Intensities",
  "TileBackgrounds",
  "TileFWHMs",
  "TilePhasings",
  "TilePrephasings",
  "TileResiduals",
];

const readList = ["R1", "R2"];

const channelList = ["1", "2", "3", "4"];

const RunTile = memo(({ runID }: RunTileProps) => {
  const [range, setRange] = useState({ min: 0, max: 100 });
  const [defaultRange, setDefaultRange] = useState(null);
  const [cycle, setCycle] = useState(1);
  const [read, setRead] = useState("R1");
  const [channel, setChannel] = useState("1");
  const [metric, setMetric] = useState("TileQ15");
  const [fixScale, setFixScale] = useState(false);

  const {
    data: flowCellData,
    isLoading,
    refetch,
  } = useFetch(
    `/run/${runID}/data?metric=${metric}&read=${read}&cycle=${cycle}&channel=${channel}`,
    !!runID
  );
  const { data: tmpData } = useFetch("/run//341/tileData");

  console.log("tileData", tmpData);

  useEffect(() => {
    if (fixScale) {
      return;
    }
    if (flowCellData) {
      const fcd = flowCellData as MetricReturnType;
      let minv = 999999;
      let maxv = -999999;

      if (!fcd.values) {
        fcd.values = [];
        for (let i = 0; i < fcd.tileLabels.length; i++) {
          fcd.values.push(-999);
        }
      }
      for (let i = 0; i < fcd.tileLabels.length; i++) {
        let value = fcd.values[i];
        if (value === -999) continue;
        if (value < minv) minv = value;
        if (value > maxv) maxv = value;
      }
      if (minv > maxv) {
        minv = 0;
        maxv = 100;
      } else if (minv === maxv) {
        minv = minv * 0.9;
        maxv = maxv * 1.1;
      }
      setRange({ min: minv, max: maxv });
      setDefaultRange({ min: minv, max: maxv });
    }
  }, [flowCellData, fixScale]);

  return (
    <div style={{ marginLeft: 100, marginTop: 50 }}>
      <LoadPanel
        position={{ of: "#metricParentDiv" }}
        visible={isLoading}
        showIndicator={true}
      />

      <div style={{ display: "flex", marginLeft: "20px", marginBottom: 5 }}>
        <span style={{ paddingTop: 8 }}>Metric:&nbsp;&nbsp;&nbsp;</span>

        <SelectBox
          value={metric}
          placeholder="Select a metric..."
          showClearButton={false}
          dataSource={metricList}
          onValueChanged={(e) => setMetric(e.value)}
          style={{ width: "190px" }}
        />
      </div>

      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", marginLeft: "20px" }}>
          <span style={{ paddingTop: 8 }}>Read:&nbsp;&nbsp;&nbsp;</span>

          <SelectBox
            value={read}
            placeholder="Select a read..."
            showClearButton={false}
            dataSource={readList}
            onValueChanged={(e) => setRead(e.value)}
            style={{ width: "100px" }}
          />
        </div>

        <div style={{ display: "flex", marginLeft: "20px" }}>
          <span style={{ paddingTop: 8 }}>Cycle:&nbsp;&nbsp;&nbsp;</span>
          <NumberBox
            style={{ width: "100px", paddingLeft: "15px" }}
            min={1}
            max={151}
            showSpinButtons
            value={cycle}
            onValueChanged={(e) => {
              setCycle(e.value);
            }}
          />
        </div>

        <div
          style={{
            display: metric.endsWith("s") ? "flex" : "none",
            marginLeft: "20px",
          }}
        >
          <span style={{ paddingTop: 8 }}>Channel:&nbsp;&nbsp;&nbsp;</span>
          <SelectBox
            value={channel}
            placeholder="Select a channel..."
            showClearButton={false}
            dataSource={channelList}
            onValueChanged={(e) => setChannel(e.value)}
            style={{ width: "100px" }}
            readOnly={!metric.endsWith("s")}
          />
        </div>
      </div>
      <br />
      <div
        style={{
          textAlign: "center",
          width: "400px",
          paddingRight: "100px",
          marginTop: "20px",
        }}
      >
        <strong>{metric}</strong>
      </div>
      <div
        id="metricParentDiv"
        style={{
          marginTop: "5px",
          display: "flex",
          width: "400px",
          height: "500px",
        }}
      >
        {flowCellData && (
          <>
            <div style={{ width: "160px", height: "500px" }}>
              <FlowCellChart
                data={flowCellData.values}
                labels={flowCellData.tileLabels}
                minv={range.min}
                maxv={range.max}
                title="S1"
                tooltipprecision={2}
                hidelegend
              />
            </div>{" "}
            <div style={{ width: "160px", height: "500px" }}>
              <FlowCellChart
                data={flowCellData.values}
                labels={flowCellData.tileLabels}
                minv={range.min}
                maxv={range.max}
                title="S2"
                tooltipprecision={2}
                hidelegend
              />
            </div>
            <div
              style={{ width: "100px", display: defaultRange ? "" : "none" }}
            >
              <ColorRangeBar
                minv={defaultRange?.min}
                maxv={defaultRange?.max}
                rangeChanged={(r) => setRange(r)}
              />
            </div>
          </>
        )}
      </div>

      <Button
        style={{ marginTop: 5 }}
        text="Refresh"
        icon="refresh"
        onClick={() => refetch()}
      />

      <CheckBox
        text="Fix Scale"
        style={{ marginLeft: "20px" }}
        value={fixScale}
        onValueChanged={(e) => {
          setFixScale(e.value);
        }}
      />
    </div>
  );
});

export default RunTile;
