import { LoadIndicator } from "devextreme-react/load-indicator";

export const BusyOverlay = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 10000000,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(75, 75, 75, .2)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadIndicator width={150} height={150} />
    </div>
  );
};
