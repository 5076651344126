import { memo, useState, useEffect, useRef, useCallback } from "react";
import { LoadPanel } from "devextreme-react/load-panel";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  Series,
  Legend,
  ValueAxis,
  Point,
  ArgumentAxis,
  Tooltip,
  ZoomAndPan,
  Label,
  Title,
} from "devextreme-react/chart";
import { ProdStatsType } from "./run-container";
import { SelectBox } from "devextreme-react";

type RunIndexingProdstatsProps = {
  data: ProdStatsType;
  isLoading: boolean;
  isDualLibrary: boolean;
};

interface StatsType {
  SampleName: string;
  PerfectMatch: number;
  PartialMatch: number;
}

const RunProdstatsIndexing = memo(
  ({ data, isLoading, isDualLibrary }: RunIndexingProdstatsProps) => {
    const chart = useRef();
    const containerDiv = useRef();
    const [chartData, setChartData] = useState<StatsType[]>([]);

    const [libraryPoolDisplay, setLibraryPoolDisplay] = useState<
      "Run Level" | "Library 1" | "Library 2"
    >("Run Level");

    const theData =
      libraryPoolDisplay === "Run Level" || !isDualLibrary
        ? data?.indexingRunStats
        : libraryPoolDisplay === "Library 1"
        ? data?.indexingLaneStats[0]
        : data?.indexingLaneStats[1];

    useEffect(() => {
      let vals: StatsType[] = [];
      if (theData) {
        for (let i = 0; i < theData.SampleIndex.length; i++) {
          const v = theData.SampleIndex[i];
          const assignmentRate = (v.NumPolonies / theData.NumPolonies) * 100;
          const partialMatch = assignmentRate * v.MismatchRate;
          const perfectMatch = assignmentRate - partialMatch;
          vals.push({
            SampleName: v.SampleName,
            PerfectMatch: perfectMatch,
            PartialMatch: partialMatch,
          });
        }
      }

      setChartData(vals);
    }, [theData]);

    useEffect(() => {
      if (chart.current && chartData)
        (chart.current as any).instance.resetVisualRange();
    }, [chart, chartData]);

    useEffect(() => {
      chart.current && (chart.current as any).instance.render();
    }, []);

    const renderTooltip = useCallback((pointInfo) => {
      if (pointInfo && pointInfo.point && pointInfo.point.data) {
        const data = pointInfo.point.data;
        return (
          <div>
            <span>{data.SampleName}</span>
            <br />
            <span>Perfect Match: {data.PerfectMatch.toPrecision(2)}%</span>
            <br />
            <span>Partial Match: {data.PartialMatch.toPrecision(2)}%</span>
            <br />
          </div>
        );
      }
      return <div />;
    }, []);

    return (
      <div ref={containerDiv} style={{ marginTop: 40 }}>
        <LoadPanel
          position={{ of: "#chart" }}
          visible={isLoading}
          showIndicator={true}
          showPane={true}
        />

        {isDualLibrary && (
          <SelectBox
            width={140}
            items={["Run Level", "Library 1", "Library 2"]}
            value={libraryPoolDisplay}
            onValueChanged={(e) => {
              setLibraryPoolDisplay(e.value);
            }}
          />
        )}

        <Chart
          id="chart"
          ref={chart}
          height="80vh"
          dataSource={chartData}
          animation={false}
        >
          <Title
            text={`Percent Assigned: ${
              theData?.AssignmentRate >= 0
                ? (theData?.AssignmentRate * 100).toFixed(2)
                : "-"
            }%`}
          />
          <CommonSeriesSettings argumentField="SampleName" type="stackedBar">
            <Point size={4} />
          </CommonSeriesSettings>

          <Series
            valueField="PartialMatch"
            name="Partial Match"
            color="orange"
          />
          <Series
            valueField="PerfectMatch"
            name="Perfect Match"
            color="green"
          />

          <Tooltip enabled={true} contentRender={renderTooltip} />
          <ValueAxis title="" />
          <ArgumentAxis title="Sample">
            <Label rotationAngle={90} displayMode="rotate" />
          </ArgumentAxis>
          <Legend
            visible={true}
            position="outside"
            horizontalAlignment="center"
            verticalAlignment="bottom"
          />
          <ZoomAndPan argumentAxis="both" valueAxis="both" />
          <Export enabled={true} />
        </Chart>
      </div>
    );
  }
);

export default RunProdstatsIndexing;
