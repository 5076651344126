import { memo, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { SelectBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import { fetchWithToken } from "../../../hooks/useFetch";
import { Run } from "../types";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  ArgumentAxis,
  ZoomAndPan,
} from "devextreme-react/chart";
import { useAuth } from "src/contexts/auth";

type ValuesChartsProps = {
  run: Run;
};

const reads = ["R1", "R2", "I1", "I2"];

const values = [
  "CleaveMin",
  "CleaveMax",
  "CleaveRange",
  "IncorpMin",
  "IncorpMax",
  "IncorpRange",
  "TrapMin",
  "TrapMax",
  "TrapRange",
  "TrapRMin",
  "TrapRMax",
  "TrapRRange",
];

const ValuesChart = memo(({ run }: ValuesChartsProps) => {
  const chart = useRef();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { getAccessTokenSilently } = useAuth();
  const [display, setDisplay] = useState("IncorpMin");
  const [read, setRead] = useState("R1");
  const containerDiv = useRef();

  useEffect(() => {
    if (!run) {
      setData([]);
      return;
    }
    const body = {
      RunID: run.runID,
      InstrumentID: run.instrumentID,
      TenantID: run.tenantID,
      Side: run.side,
      InstrumentSerialNumber: run.instrumentSerialNumber,
      InstrumentName: run.instrumentName,
    };
    setLoading(true);
    fetchWithToken(getAccessTokenSilently, "/run/valueLogChart", {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((result) => {
        setData(result);
        console.log("returned values", result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        error.text().then((text) => {
          alert(text);
          setData([]);
        });
      });
  }, [getAccessTokenSilently, run]);

  useEffect(() => {
    chart.current && (chart.current as any).instance.render();
  }, []);

  return (
    <div ref={containerDiv} style={{ marginTop: 40 }}>
      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={values}
              value={display}
              onValueChanged={(e) => {
                setDisplay(e.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Read:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={reads}
              value={read}
              onValueChanged={(e) => {
                setRead(e.value);
              }}
            />
          </td>
        </tr>
      </table>

      <Chart
        id="chart"
        ref={chart}
        height="80vh"
        dataSource={data.filter((x) => x.Read === read)}
        onLegendClick={useCallback((e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }, [])}
      >
        <CommonSeriesSettings
          argumentField="Cycle"
          valueField={useMemo(() => {
            return display;
          }, [display])}
          type="line"
        >
          <Point size={4} />
        </CommonSeriesSettings>
        <SeriesTemplate nameField="Group" />
        <ValueAxis title={display}></ValueAxis>
        <ArgumentAxis title="Cycle" allowDecimals={true} />
        <Legend
          visible={true}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />
      </Chart>
      {/* <Button
          style={{ marginTop: 5 }}
          text={isFullScreen ? "Restore" : "Full Screen"}
          icon="fullscreen"
          onClick={() => {
            if (document.fullscreenElement) {
              document.exitFullscreen();
            } else {
              (containerDiv.current as any).requestFullscreen();
            }
            setIsFullScreen((x) => !x);
          }}
        /> */}
      <LoadPanel
        position={{ of: "#chart" }}
        visible={loading}
        showIndicator={true}
        showPane={true}
      />
    </div>
  );
});

export default ValuesChart;
