import { Switch, Route, Redirect } from "react-router-dom";
import appInfo from "./app-info";
import routes from "./app-routes";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";

const Content = () => {
  return (
    <SideNavBarLayout title={appInfo.title}>
      <Switch>
        {routes.map(({ path, component }) => (
          <Route exact key={path} path={path} component={component} />
        ))}
        <Redirect to={"/instruments"} />
      </Switch>
      {/* <Footer>
        Copyright © 2011-{new Date().getFullYear()} Element Biosciences
        <br />
        All trademarks or registered trademarks are property of their respective
        owners.
      </Footer> */}
    </SideNavBarLayout>
  );
};

export default Content;
