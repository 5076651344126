import React, {
  useState,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import { SelectBox } from "devextreme-react/select-box";
import { Button } from "devextreme-react/button";

import CustomStore from "devextreme/data/custom_store";

import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  SeriesTemplate,
  Grid,
  Legend,
  Point,
  ZoomAndPan,
  ConstantLine,
  Label,
  Aggregation,
} from "devextreme-react/chart";

import {
  RangeSelector,
  Scale,
  SliderMarker,
} from "devextreme-react/range-selector";

import { useFetch } from "../../../hooks/useFetch";
import { RunMetricProps, RunEvent } from "../types";
import { getLinesFromRunEvents } from "./run-utils";

const maxRange = { hours: 24 };
const tickInterval = { hours: 3 };

const metrics = [
  { sensor: "pressure_sensor@A", ylabel: "Pressure" },
  { sensor: "pressure_sensor@B", ylabel: "Pressure" },
  // "laser_green",
  // "laser_red",
  { sensor: "nest_tec@A", ylabel: "Temperature" },
  { sensor: "nest_tec@B", ylabel: "Temperature" },

  { sensor: "fridge@A", ylabel: "Temperature" },
  { sensor: "fridge@B", ylabel: "Temperature" },

  { sensor: "therm_exhaust@A", ylabel: "Temperature" },
  { sensor: "therm_exhaust@B", ylabel: "Temperature" },

  { sensor: "therm_inlet", ylabel: "Temperature" },

  { sensor: "therm_optics", ylabel: "Temperature" },

  { sensor: "therm_valve_table", ylabel: "Temperature" },

  { sensor: "therm_psu", ylabel: "Temperature" },

  { sensor: "valve@A", ylabel: "Position" },
  { sensor: "valve@B", ylabel: "Position" },

  { sensor: "af_ctrl", ylabel: "Value" },

  { sensor: "z_stage", ylabel: "Position" },
  // "laser_af",
  { sensor: "tip_tilt_motor_1", ylabel: "Position" },
  { sensor: "tip_tilt_motor_2", ylabel: "Position" },
];

const RunMetric = memo(({ instrumentID, runID, typeRun }: RunMetricProps) => {
  const fetchPrefix = typeRun === "Spatial" ? "spatialRun" : "run";
  const chartRef = useRef(null);
  const [metric, setMetric] = useState(metrics[0]);

  const [constantLines, setConstantLines] = useState<
    { value: Date; label: string; key: string }[]
  >([]);
  const [fullRange, setFullRange] = useState<Date[]>(null);
  const [displayRange, setDisplayRange] = useState<Date[]>(null);

  const valid = !!runID && !!instrumentID;
  const {
    data: eventData,
    isLoading,
    refetch,
  } = useFetch(`/${fetchPrefix}/${runID}/events`, valid);

  const url =
    !!metric && !!displayRange && !!instrumentID
      ? "/instrument/" +
        instrumentID +
        "/metric/" +
        metric.sensor +
        "?start=" +
        displayRange[0].toISOString() +
        "&end=" +
        displayRange[1].toISOString()
      : null;
  console.log(url);
  const {
    data: metricData,
    isLoading: metricIsLoading,
    refetch: metricRefetch,
  } = useFetch(url, url !== null);

  useEffect(() => {
    if (isLoading) return;
    const runEvents = eventData as RunEvent[];
    if (runEvents) {
      if (runEvents.length === 0) {
        runEvents.push({
          sensor: "",
          name: "An hour ago",
          timestamp: new Date(new Date().getTime() - 60 * 60 * 1000),
          cycle: 0,
          read: "",
          batch: "",
        });
        runEvents.push({
          sensor: "",
          name: "Now",
          cycle: 0,
          read: "",
          timestamp: new Date(),
          batch: "",
        });
      } else if (runEvents.length === 1)
        runEvents.push({
          sensor: "",
          name: "Now",
          cycle: 0,
          read: "",
          timestamp: new Date(),
          batch: "",
        });

      setConstantLines(getLinesFromRunEvents(runEvents, true, typeRun));
      const lastDate = new Date(
        new Date(runEvents[runEvents.length - 1].timestamp).getTime() +
          1000 * 60 * 60 * 2
      );

      const tmprange = [new Date(runEvents[0].timestamp), lastDate];
      console.log(tmprange);
      setFullRange(tmprange);
      setDisplayRange([new Date(lastDate.getTime() - 40 * 60000), lastDate]);
    } else {
      setConstantLines(null);
      setFullRange(null);
      setDisplayRange(null);
    }
  }, [chartRef, eventData, isLoading, typeRun]);

  var loadData = useCallback(async () => {
    if (metricIsLoading) return [];
    return metricData;
  }, [metricIsLoading, metricData]);

  var customStore = useMemo(
    () =>
      new CustomStore({
        key: "id",
        load: loadData,
      }),
    [loadData]
  );

  useEffect(() => {
    chartRef.current && chartRef.current.instance.render();
  }, []);

  // useEffect(() => {
  //   if (refreshCntr > 0) {
  //     console.log(refreshCntr);
  //     chartRef.current.instance.refresh();
  //   }
  // }, [refreshCntr]);

  const ConstantLines = useMemo(() => {
    if (!constantLines || constantLines.length < 2 || !displayRange)
      return null;
    let prevValue = 0;
    let prevPos = "top";

    return constantLines
      .filter((v) => v.value >= displayRange[0] && v.value <= displayRange[1])
      .map((v) => {
        let pos = "top";
        if (v.value.getTime() - prevValue < 3 * 60000) {
          if (prevPos === "top") pos = "center";
          else if (prevPos === "center") pos = "bottom";
          else pos = "top";
        }
        prevPos = pos;
        prevValue = v.value.getTime();

        return (
          <ConstantLine
            key={v.key}
            width={2}
            value={v.value}
            color="brown"
            dashStyle="dash"
          >
            <Label
              verticalAlignment={pos}
              text={v.label}
              font={{ color: "brown" }}
            />
          </ConstantLine>
        );
      });
  }, [constantLines, displayRange]);

  return (
    <div style={{ flex: 1, marginLeft: 50, marginTop: 40 }}>
      {constantLines && constantLines.length > 0 && (
        <div style={{ marginBottom: 15 }}>
          Current Step: {constantLines[constantLines.length - 1].label}
        </div>
      )}
      <SelectBox
        value={metric}
        displayExpr="sensor"
        placeholder="Select a metric..."
        showClearButton={false}
        dataSource={metrics}
        onValueChanged={(e) => setMetric(e.value)}
        style={{ width: "300px" }}
      />
      <br />
      {fullRange && displayRange && (
        <RangeSelector
          id="range-selector"
          value={displayRange}
          title="Time Range"
          onValueChanged={(e) => {
            setDisplayRange(e.value as any);
          }}
        >
          <Scale
            startValue={fullRange[0]}
            endValue={fullRange[1]}
            maxRange={maxRange}
            tickInterval={tickInterval}
          />
          <SliderMarker format="shortDateShortTime" />
        </RangeSelector>
      )}

      <Chart
        height={window.screen.height - 700}
        id="chart"
        ref={chartRef}
        palette="Soft Blue"
        dataSource={customStore}
        scrollBar={{ visible: true, position: "bottom" }}
        onLegendClick={useCallback((e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }, [])}
      >
        <ArgumentAxis
          argumentType={"datetime"}
          title={"Time"}
          wholeRange={displayRange}
        >
          <Grid visible={true} />
          {ConstantLines}
        </ArgumentAxis>

        <SeriesTemplate nameField="sensor" />

        <CommonSeriesSettings
          argumentField="timestamp"
          valueField="value"
          type="line"
        >
          <Aggregation enabled={false} />
          <Point size={4} />
        </CommonSeriesSettings>

        <ValueAxis title={metric.ylabel} />
        <Legend visible={true} />

        <ZoomAndPan argumentAxis="both" valueAxis="both" />
      </Chart>

      <LoadPanel
        // shadingColor="rgba(0,0,0,0.4)"
        position={{ of: "#chart" }}
        visible={metricIsLoading}
        showIndicator={true}
        //  shading={true}
        //  showPane={true}
      />

      <Button
        style={{ marginTop: 5 }}
        text="Refresh"
        icon="refresh"
        onClick={() => {
          refetch();
          metricRefetch();
        }}
      />
    </div>
  );
});

export default RunMetric;
