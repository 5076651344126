import { memo } from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

type RunValuesLogProps = {
  runID: string;
};

const RunValuesLog = memo(({ runID }: RunValuesLogProps) => {
  const { data, isLoading } = useFetch(`/run/${runID}/valuesLog`, !!runID);

  return (
    <div id="chart" style={{ marginTop: 40 }}>
      <LoadPanel
        position={{ of: "#chart" }}
        visible={isLoading}
        showIndicator={true}
        showPane={true}
      />

      <span>Size of log: {data?.SizeInMB} MB</span>
      <br />
      <br />
      <span>Time to transfer: {data?.TimeInSec} sec</span>
    </div>
  );
});

export default RunValuesLog;
