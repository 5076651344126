export default () => {
  return (
    <div style={{ margin: 50, fontSize: 18 }}>
      <h2 style={{ marginBottom: 100 }}>Privacy Policy</h2>

      <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
        <div>
          Your privacy is very important to us. Accordingly, we have developed
          this Policy in order for you to understand how we collect, use,
          communicate and disclose and make use of personal information. The
          following outlines our privacy policy.
        </div>
        <br />
        <br />

        <div>
          We do not collect any information, personal or otherwise from the use
          of this app.
          <br />
          <br />
          <br />
          We are committed to conducting our business in accordance with these
          principles in order to ensure that the confidentiality of personal
          information is protected and maintained.
        </div>
      </div>
    </div>
  );
};
