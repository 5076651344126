enum Comparison {
  Lower,
  Higher,
  Between,
}
interface GradingType {
  LowThresh: number;
  HighThresh: number;
  Type: Comparison;
  LowLowThresh?: number; // used for Between Comparisons
  HighHighThresh?: number; // used for Between Comparisons
}

const defaultMap: Map<string, GradingType> = new Map([
  ["q30", { LowThresh: 0.85, HighThresh: 0.9, Type: Comparison.Higher }],
  //   ["error", { LowThresh: 0.8, HighThresh: 1, Type: Comparison.Lower }],
  ["poloniesPF", { LowThresh: 800, HighThresh: 1000, Type: Comparison.Higher }],
  [
    "indexAssignmentPercent",
    { LowThresh: 90, HighThresh: 95, Type: Comparison.Higher },
  ],
  ["runTime", { LowThresh: 38, HighThresh: 40, Type: Comparison.Lower }],
]);

const HighOutput300CycleMap: Map<string, GradingType> = defaultMap;

const UltraQ300CycleMap: Map<string, GradingType> = new Map([
  ["q50", { LowThresh: 0.69, HighThresh: 0.72, Type: Comparison.Higher }],
  [
    "poloniesPF",
    {
      LowLowThresh: 700,
      LowThresh: 800,
      HighThresh: 1000,
      HighHighThresh: 1050,
      Type: Comparison.Between,
    },
  ],
  ["runTime", { LowThresh: 44, HighThresh: 45, Type: Comparison.Lower }],
]);

const MedOutput300CycleMap: Map<string, GradingType> = new Map([
  ["poloniesPF", { LowThresh: 400, HighThresh: 500, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 31, HighThresh: 33, Type: Comparison.Lower }],
]);

const LowOutput300CycleMap: Map<string, GradingType> = new Map([
  ["poloniesPF", { LowThresh: 200, HighThresh: 250, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 27, HighThresh: 29, Type: Comparison.Lower }],
]);

const HighOutput150CycleMap: Map<string, GradingType> = new Map([
  ["runTime", { LowThresh: 28, HighThresh: 30, Type: Comparison.Lower }],
]);

const MedOutput150CycleMap: Map<string, GradingType> = new Map([
  ["poloniesPF", { LowThresh: 400, HighThresh: 500, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 20, HighThresh: 22, Type: Comparison.Lower }],
]);

const HighOutput600CycleMap: Map<string, GradingType> = new Map([
  ["q30", { LowThresh: 0.8, HighThresh: 0.85, Type: Comparison.Higher }],
  ["poloniesPF", { LowThresh: 240, HighThresh: 300, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 60, HighThresh: 64, Type: Comparison.Lower }],
]);

const MedOutput600CycleMap: Map<string, GradingType> = new Map([
  ["q30", { LowThresh: 0.8, HighThresh: 0.85, Type: Comparison.Higher }],
  ["poloniesPF", { LowThresh: 80, HighThresh: 100, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 51, HighThresh: 55, Type: Comparison.Lower }],
]);

const Trinity150CycleMap: Map<string, GradingType> = new Map([
  ["poloniesPF", { LowThresh: 700, HighThresh: 800, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 24, HighThresh: 26, Type: Comparison.Lower }],
]);

const Trinity300CycleMap: Map<string, GradingType> = new Map([
  ["poloniesPF", { LowThresh: 700, HighThresh: 800, Type: Comparison.Higher }],
  ["runTime", { LowThresh: 38, HighThresh: 40, Type: Comparison.Lower }],
]);

const MapLookup = new Map([
  ["820-00014", MedOutput150CycleMap],
  ["820-00021", MedOutput150CycleMap],
  ["820-00015", HighOutput150CycleMap],
  ["820-00022", HighOutput150CycleMap],
  ["820-00011", LowOutput300CycleMap],
  ["820-00018", LowOutput300CycleMap],
  ["820-00012", MedOutput300CycleMap],
  ["820-00019", MedOutput300CycleMap],
  ["820-00013", HighOutput300CycleMap],
  ["820-00020", HighOutput300CycleMap],
  ["820-00017", MedOutput600CycleMap],
  ["820-00023", MedOutput600CycleMap],
  ["820-00016", HighOutput600CycleMap],
  ["820-00024", HighOutput600CycleMap],
  ["820-00026", UltraQ300CycleMap],
  ["710-00986", UltraQ300CycleMap],
  ["820-00030", Trinity150CycleMap],
  ["820-00031", Trinity300CycleMap],
]);

export const gradeMetricForRun = (
  metric: string,
  value: number,
  partNumber: string
): string => {
  if (value === -999 || isNaN(value)) return undefined;
  const grading = MapLookup.get(partNumber);
  let criteria = grading?.get(metric);
  if (!criteria) criteria = defaultMap.get(metric);

  if (criteria) {
    if (criteria.Type === Comparison.Higher) {
      if (value >= criteria.HighThresh) return "green";
      if (value >= criteria.LowThresh) return "orange";
      return "red";
    }
    if (criteria.Type === Comparison.Lower) {
      if (value <= criteria.LowThresh) return "green";
      if (value <= criteria.HighThresh) return "orange";
      return "red";
    }

    if (criteria.Type === Comparison.Between) {
      if (value < criteria.LowLowThresh) return "red";
      if (value < criteria.LowThresh) return "orange";
      if (value > criteria.HighHighThresh) return "red";
      if (value > criteria.HighThresh) return "orange";
      return "green";
    }
  }

  return undefined;
};

export const gradeMetricForSpatialRun = (
  metric: string,
  value: number
): string => {
  if (metric === "cellCount") {
    if (value <= 0) return undefined;
    if (value < 108000) return "red";
    if (value < 180000) return "orange";
    return "green";
  }
  return undefined;
};
