import React, { useState, useMemo, useRef, useCallback } from "react";
//import TileView from "devextreme-react/tile-view";
import "./instruments.scss";
import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";
import ReactJson from "react-json-view";
import CustomStore from "devextreme/data/custom_store";

import DataGrid, {
  ColumnChooser,
  Column,
  Button,
  FilterRow,
  Paging,
  Pager,
  GroupPanel,
  GroupItem,
  Editing,
  Summary,
  Export,
  MasterDetail,
  StateStoring,
  SearchPanel,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import CalibrationViewer from "./calibration";
import RunLogs from "../runs/components/run-logs";

const cnstMode = { mode: "single" };
const cnstEnabled = { enabled: true };
const cnstVisible = { visible: true };
const cnstPageSizes = [30, 50, 100, "all"];

const cleanDate = (v) => (v.value < new Date(2020, 1, 1) ? "" : v.valueText);

export default ({ instrumentSelected }) => {
  const { getAccessTokenSilently } = useAuth();
  const [refetchToggle, setRefetchToggle] = useState(false);

  const [selectedInstrument, setSelectedInstrument] = useState({
    tenant: "",
    serialNumber: "",
    instrumentID: "",
    instrumentName: "",
  });

  const [showCalibration, setShowCalibration] = useState(false);
  const [showFiles, setShowFiles] = useState(false);

  const grid = useRef();

  const loadData = useCallback(async () => {
    const instruments = await fetchWithToken(
      getAccessTokenSilently,
      "/instrument"
    );

    for (let i = 0; i < instruments.length; i++) {
      instruments[i].details = JSON.parse(instruments[i].raw);
      const instrumentConfigStat = instruments[i].details;
      instruments[i].e3Version = instrumentConfigStat.E3Version;
      instruments[i].macAddress = instrumentConfigStat.MacAddress;
      instruments[i].firmwareVersions = instrumentConfigStat.FirmwareVersions;
      instruments[i].installedSnaps = instrumentConfigStat.InstalledSnaps;
      const kiosk = instrumentConfigStat.InstalledSnaps.find(
        (v) => v.Name === "elembio-kiosk"
      );
      instruments[i].kiosk = kiosk
        ? `${kiosk?.RevisionNumber} | ${kiosk?.Version}`
        : "";
      const control = instrumentConfigStat.InstalledSnaps.find(
        (v) => v.Name === "elembio-control"
      );
      instruments[i].control = control
        ? `${control?.RevisionNumber} | ${control?.Version}`
        : "";

      const platform = instrumentConfigStat.InstalledSnaps.find(
        (v) => v.Name === "elembio-platform"
      );
      instruments[i].platform = platform
        ? `${platform?.RevisionNumber} | ${platform?.Version}`
        : "";
      const molecule = instrumentConfigStat.InstalledSnaps.find(
        (v) => v.Name === "elembio-molecule"
      );
      instruments[i].molecule = molecule
        ? `${molecule?.RevisionNumber} | ${molecule?.Version}`
        : "";
      instruments[i].validationSet =
        instrumentConfigStat.EnforcedValidationSets?.length > 0
          ? instrumentConfigStat.EnforcedValidationSets.map((v) => v.Name).join(
              ","
            )
          : "";
    }
    return instruments;
  }, [getAccessTokenSilently, refetchToggle]);

  const updateData = useCallback(
    async (key, values) => {
      await fetchWithToken(getAccessTokenSilently, "/instrument/" + key, {
        method: "PATCH",
        body: JSON.stringify(values),
      });
    },
    [getAccessTokenSilently]
  );

  const onSelectionChanged = useCallback(
    (e) => {
      instrumentSelected && instrumentSelected(e.selectedRowsData[0]);
    },
    [instrumentSelected]
  );

  const refreshOptions = useMemo(() => {
    return {
      icon: "refresh",
      hint: "Refresh",
      text: "",
      stylingMode: "icon",
      onClick: () => {
        setRefetchToggle((v) => !v);
      },
    };
  }, []);

  const resetOptions = useMemo(() => {
    return {
      hint: "Reset to default table layout",
      text: "Reset",
      stylingMode: "text",
      onClick: () => {
        grid.current.instance.state({});
      },
    };
  }, []);

  var customStore = useMemo(
    () =>
      new CustomStore({
        key: "instrumentID",
        load: loadData,
        update: updateData,
      }),
    [loadData, updateData]
  );

  return (
    <React.Fragment>
      <div
        id="maindiv"
        className={"content-block"}
        style={{ height: "calc(100vh - 180px)" }}
      >
        <DataGrid
          ref={grid}
          dataSource={customStore}
          showBorders
          showRowLines
          selection={cnstMode}
          wordWrapEnabled
          showColumnLines
          rowAlternationEnabled
          allowColumnResizing
          allowColumnReordering
          columnResizingMode={"widget"}
          columnHidingEnabled
          columnAutoWidth
          columnFixing={cnstEnabled}
          headerFilter={cnstVisible}
          onSelectionChanged={onSelectionChanged}
          height="100%"
          style={{
            display: showCalibration || showFiles ? "none" : undefined,
          }}
        >
          <Toolbar>
            <Item location="after" widget="dxButton" options={refreshOptions} />
            <Item name="columnChooserButton" />
            <Item name="groupPanel" />
            <Item name="searchPanel" />
            <Item name="exportButton" />
            {/* <Item location="before" widget="dxButton" options={spacerOptions} /> */}
            <Item location="before" widget="dxButton" options={resetOptions} />
          </Toolbar>
          <ColumnChooser enabled />
          <SearchPanel visible />
          <Column
            dataField={"instrumentName"}
            caption={"Instrument"}
            dataType={"string"}
            width={150}
            allowEditing={false}
          />
          <Column
            dataField="instrumentNickname"
            caption={"Nickname"}
            dataType={"string"}
            width={150}
          />
          <Column
            dataField={"instrumentID"}
            caption="ID"
            allowEditing={false}
          />
          <Column dataField={"serialNumber"} allowEditing={false} />
          <Column dataField={"hostName"} allowEditing={false} />
          <Column dataField={"tenantID"} allowEditing={false} />
          <Column type="buttons" visible width={50}>
            <Button
              hint="Show Calibration File"
              icon="file"
              onClick={(e) => {
                setSelectedInstrument({
                  tenant: e.row.data.tenantID,
                  serialNumber: e.row.data.serialNumber,
                  instrumentID: e.row.data.instrumentID,
                });
                setShowCalibration(true);
              }}
            />
          </Column>
          <Column
            dataField={"lastUpdated"}
            allowEditing={false}
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={cleanDate}
          />

          <Column
            dataField={"lastMaintenanceWashSideA"}
            allowEditing={false}
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={cleanDate}
          />
          <Column
            dataField={"lastMaintenanceWashSideB"}
            allowEditing={false}
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={cleanDate}
          />

          <Column dataField="model" allowEditing={false} />
          <Column dataField="ip" caption="IP" allowEditing={false} />

          <Column dataField="ipForAddress" caption="IP For Address" allowEditing={false} />
          <Column dataField="lat" allowEditing={false} />
          <Column dataField="lon"  allowEditing={false} />
          <Column dataField="country" allowEditing={false} />
          <Column dataField="city" allowEditing={false} />
          <Column dataField="region"  allowEditing={false} />
          <Column dataField="org"  allowEditing={false} />

          <Column
            dataField="macAddress"
            caption="MAC Address"
            allowEditing={false}
          />
          <Column dataField={"version"} allowEditing={false} />
          <Column dataField={"validationSet"} allowEditing={false} />
          <Column
            dataField={"e3Version"}
            caption={"E3 Version"}
            allowEditing={false}
          />
          <Column dataField={"kiosk"} allowEditing={false} />
          <Column dataField={"control"} allowEditing={false} />
          <Column dataField={"platform"} allowEditing={false} />
          <Column dataField={"molecule"} allowEditing={false} />
          <Column
            dataField={"ssdFirmware"}
            allowEditing={false}
            caption="SSD Firmware"
          />
          <Column dataField={"watchdogTimeout"} allowEditing={false} />
          <Column dataField={"refreshTimer"} allowEditing={false} />
          <Column visible type="buttons">
            <Button
              text="Browse Files..."
              onClick={(e) => {
                setSelectedInstrument({
                  tenant: e.row.data.tenantID,
                  serialNumber: e.row.data.serialNumber,
                  instrumentID: e.row.data.instrumentID,
                  instrumentName: e.row.data.instrumentName,
                });
                setShowFiles(true);
              }}
            />
          </Column>
          <GroupPanel visible />
          <Summary>
            <GroupItem
              column={"title"}
              summaryType={"count"}
              displayFormat={"{0}"}
            />
          </Summary>
          <Paging defaultPageSize={50} />
          <Pager
            showPageSizeSelector
            allowedPageSizes={cnstPageSizes}
            showInfo
            showNavigationButtons
          />
          <FilterRow visible />
          <Export
            enabled
            fileName={"InstrumentVersions"}
            allowExportSelectedData={false}
          />
          <Editing
            mode="cell" //"form" "batch" "popup" "cell"
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}
          />
          <StateStoring
            enabled={false}
            type="localStorage"
            storageKey="instrumentTableV3"
          />
          <MasterDetail
            enabled
            component={(props) => {
              return (
                <div style={{ background: "white" }}>
                  <ReactJson
                    theme="bright"
                    root={"details"}
                    quotesOnKeys={false}
                    enableEdit={false}
                    enableAdd={false}
                    enableDelete={false}
                    displayDataTypes={false}
                    displayObjectSize={false}
                    src={props.data.data.details}
                  />
                </div>
              );
            }}
          />
        </DataGrid>

        {showCalibration && (
          <CalibrationViewer
            instrumentID={selectedInstrument.instrumentID}
            tenant={selectedInstrument.tenant}
            serialNumber={selectedInstrument.serialNumber}
            onClose={() => setShowCalibration(false)}
          />
        )}

        {showFiles && (
          <RunLogs
            instrumentID={selectedInstrument.instrumentID}
            tenantID={selectedInstrument.tenant}
            instrumentSerialNumber={selectedInstrument.serialNumber}
            instrumentName={selectedInstrument.instrumentName}
            onClose={() => setShowFiles(false)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

// export default () => {
//   return (
//     <>
//       <div className="wrap">
//         <Resizable
//           className="resizable resizable1"
//           autoHide
//           onResize={({ element, width }) => {
//             var remainingSpace = element.parentNode.offsetWidth - width,
//               divTwoWidth =
//                 (remainingSpace / element.parentNode.offsetWidth) * 100 + "%";
//             element.nextElementSibling.style.width = divTwoWidth;
//           }}
//         >
//           <div className="inner">
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry. Lorem Ipsum has been the industry's standard dummy text
//             ever since the 1500s, when an unknown printer took a galley of type
//             and scrambled it to make a type specimen book. It has survived not
//             only five centuries, but also the leap into electronic typesetting,
//             remaining essentially unchanged. It was popularised in the 1960s
//             with the release of Letraset sheets containing Lorem Ipsum passages,
//             and more recently with desktop publishing software like Aldus
//             PageMaker including versions of Lorem Ipsum.
//           </div>
//         </Resizable>
//         <div className="resizable resizable2">
//           <div className="inner">
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry. Lorem Ipsum has been the industry's standard dummy text
//             ever since the 1500s, when an unknown printer took a galley of type
//             and scrambled it to make a type specimen book. It has survived not
//             only five centuries, but also the leap into electronic typesetting,
//             remaining essentially unchanged. It was popularised in the 1960s
//             with the release of Letraset sheets containing Lorem Ipsum passages,
//             and more recently with desktop publishing software like Aldus
//             PageMaker including versions of Lorem Ipsum.
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
