import React, { useRef, useEffect, useState, useCallback } from "react";

interface ColorRangeBarProps {
  minv: number;
  maxv: number;
  rangeChanged?: ({ min, max: number }) => void;
}

const getTicks = (
  min: number,
  max: number
): { start: number; end: number; spacing: number } => {
  const diff = max - min;
  const pLow = Math.floor(Math.log10(diff / 10.0));
  const pHigh = Math.floor(Math.log10(diff / 3.0));

  let ret = 1;
  if (pLow !== pHigh) ret = Math.pow(10, pHigh);
  else ret = 2 * Math.pow(10, pLow);

  const i = Math.ceil(min / ret);
  const j = Math.floor(max / ret);
  return { start: i * ret, end: j * ret, spacing: ret };
};

(window as any).getTicks = getTicks;

const vPadding = 50;
const top = vPadding;
const barWidth = 20;
const tickWidth = 5;

let origMouseY = 0;
let origRange = { min: 0, max: 1 };
let dragging = false;

export default (props: ColorRangeBarProps) => {
  const myCanvas = useRef(null);
  const [range, setRange] = useState<{ min: number; max: number }>({
    min: props.minv,
    max: props.maxv,
  });
  const [dimensions, setDimensions] = useState<{
    canvasWidth;
    canvasHeight: number;
  }>({
    canvasWidth: 0,
    canvasHeight: 0,
  });

  const bottom = dimensions.canvasHeight - vPadding;
  const barHeight = bottom - top;

  useEffect(() => {
    setRange({ min: props.minv, max: props.maxv });
  }, [props.minv, props.maxv]);

  useEffect(() => {
    var PIXEL_RATIO = (function () {
      var ctx = document.createElement("canvas").getContext("2d") as any,
        dpr = window.devicePixelRatio || 1,
        bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;
      return dpr / bsr;
    })();
    if (myCanvas && dimensions.canvasWidth > 0) {
      const width = dimensions.canvasWidth;
      const height = dimensions.canvasHeight;
      const can = myCanvas.current;
      const ratio = PIXEL_RATIO;
      can.width = width * ratio;
      can.height = height * ratio;
      can.style.width = width + "px";
      can.style.height = height + "px";
      can.getContext("2d").setTransform(ratio, 0, 0, ratio, 0, 0);
    }
  }, [myCanvas, dimensions]);

  const setNewRange = useCallback(
    (arg: { min: number; max: number }): void => {
      props.rangeChanged && props.rangeChanged(arg);
      setRange(arg);
    },
    [props]
  );

  const onDoubleClick = useCallback((e) => {}, []);

  const onMouseDown = useCallback(
    (e) => {
      origMouseY = e.offsetY;
      origRange = range;
      dragging = true;
    },
    [range]
  );

  const onMouseMove = useCallback(
    (e) => {
      if (dragging) {
        const delta = e.offsetY - origMouseY;
        const deltaVal = (delta / barHeight) * (range.max - range.min);
        setNewRange({
          min: origRange.min + deltaVal,
          max: origRange.max + deltaVal,
        });
      }
    },
    [barHeight, range, setNewRange]
  );

  const onMouseExit = useCallback((e) => {
    dragging = false;
  }, []);

  const onKeyUp = useCallback((e) => {}, []);

  const onMouseUp = useCallback((e) => {
    dragging = false;
  }, []);

  const onMouseWheel = useCallback(
    (e) => {
      const prevSpread = range.max - range.min;
      const frac = (bottom - e.offsetY) / barHeight; // must stay const
      const val = frac * prevSpread + range.min; // must stay const

      let ratio = 1.05;
      if (e.deltaY < 0) ratio = 1 / ratio;
      const newSpread = prevSpread * ratio;

      const newmin = val - frac * newSpread;
      const newmax = newmin + newSpread;
      setNewRange({ min: newmin, max: newmax });
      e.preventDefault();
    },
    [range, barHeight, bottom, setNewRange]
  );

  const onResize = useCallback((e) => {}, []);

  const drawAll = useCallback(
    (ctx) => {
      ctx.clearRect(0, 0, dimensions.canvasWidth, dimensions.canvasHeight);
      var altcolors = [
        "#ffffd9",
        "#edf8b1",
        "#c7e9b4",
        "#7fcdbb",
        "#41b6c4",
        "#1d91c0",
        "#225ea8",
        "#253494",
        "#081d58",
      ];

      // Create gradient
      var grd = ctx.createLinearGradient(0, top, 0, barHeight);

      const L = altcolors.length;

      for (var i = 0; i < L; i++) {
        grd.addColorStop((L - 1 - i) / (L - 1), altcolors[i]);
      }

      // Fill with gradient
      ctx.fillStyle = grd;
      ctx.fillRect(0, top, barWidth, barHeight);

      const ticks = getTicks(range.min, range.max);

      ctx.beginPath();
      ctx.strokeStyle = "rgba(0, 0, 0, 1)";
      ctx.lineWidth = 0.75;
      ctx.moveTo(barWidth, top);
      ctx.lineTo(barWidth, bottom);

      ctx.fillStyle = "lightgray";
      const x = barWidth + tickWidth + 1;
      for (let val = ticks.start; val <= ticks.end; val += ticks.spacing) {
        let y =
          bottom -
          ((val - range.min) / (range.max - range.min)) * barHeight +
          1;
        ctx.fillText(val.toLocaleString(), x, y + 3);
        ctx.moveTo(barWidth, y);
        ctx.lineTo(barWidth + tickWidth, y);
      }

      ctx.stroke();
    },
    [barHeight, bottom, range, dimensions]
  );

  useEffect(() => {
    if (myCanvas && dimensions.canvasWidth > 0) {
      const cv = myCanvas.current;
      var ctx = cv.getContext("2d");
      drawAll(ctx);
    }
  }, [myCanvas, dimensions, drawAll]);

  useEffect(() => {
    if (myCanvas) {
      setDimensions({
        canvasWidth: document.getElementById("parentDiv").clientWidth,
        canvasHeight: document.getElementById("parentDiv").clientHeight,
      });
      const cvref = myCanvas.current;
      // window.addEventListener("resize", forceRedraw);
      cvref.addEventListener("keydown", onKeyUp);
      cvref.addEventListener("mousedown", onMouseDown);
      cvref.addEventListener("mousemove", onMouseMove);
      cvref.addEventListener("mouseup", onMouseUp);
      cvref.addEventListener("wheel", onMouseWheel);
      cvref.addEventListener("mouseleave", onMouseExit);
      cvref.addEventListener("dblclick", onDoubleClick);
      return () => {
        cvref.removeEventListener("mousedown", onMouseDown);
        cvref.removeEventListener("mousemove", onMouseMove);
        cvref.removeEventListener("mouseup", onMouseUp);
        cvref.removeEventListener("mouseleave", onMouseExit);
        cvref.removeEventListener("dblclick", onDoubleClick);
        cvref.removeEventListener("wheel", onMouseWheel);
        cvref.removeEventListener("keydown", onKeyUp);
        //   window.removeEventListener("resize", onResize);
      };
    }
  }, [
    myCanvas,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onDoubleClick,
    onMouseExit,
    onKeyUp,
    onResize,
    onMouseWheel,
  ]);

  return (
    <div id="parentDiv" style={{ marginLeft: "10px", height: "100%" }}>
      <canvas ref={myCanvas}></canvas>
    </div>
  );
};
