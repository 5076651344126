import React, {
  useState,
  memo,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { LoadPanel } from "devextreme-react/load-panel";
import { SelectBox } from "devextreme-react/select-box";
import { Button } from "devextreme-react/button";

import CustomStore from "devextreme/data/custom_store";

import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  SeriesTemplate,
  Grid,
  Legend,
  Point,
  ZoomAndPan,
  ConstantLine,
  Label,
  Aggregation,
} from "devextreme-react/chart";

import { useFetch } from "../../../hooks/useFetch";
import { CheckBox } from "devextreme-react";
import { TypeRun } from "../types";
import { getLinesFromRunEvents } from "./run-utils";

export type RunMetricProps = {
  instrumentID: string;
  runID: string;
  typeRun: TypeRun;
};

type RunEvent = {
  name: string;
  timestamp: Date;
  sensor: string;
  cycle: number;
  read: string;
  batch: string;
};

const brownColor = { color: "brown" };
const metrics = [
  { sensor: "DiskPercentUsed", ylabel: "%" },
  { sensor: "MemoryPercentUsed", ylabel: "%" },
  { sensor: "OverallCpuPercent", ylabel: "%" },
  { sensor: "CoreCPU", ylabel: "%" },
  { sensor: "CoreTemp", ylabel: "degC" },
  { sensor: "CoreFrequency", ylabel: "GHz" },
  { sensor: "TempStats", ylabel: "degC" },
];

const RunSystemStats = memo(
  ({ instrumentID, runID, typeRun }: RunMetricProps) => {
    const fetchPrefix = typeRun === "Spatial" ? "spatialRun" : "run";

    const chartRef = useRef(null);
    const [metric, setMetric] = useState(metrics[0]);
    const [showCycleLines, setShowCycleLines] = useState(false);

    const [constantLines, setConstantLines] = useState<
      { value: Date; label: string; key: string }[]
    >([]);
    const [fullRange, setFullRange] = useState<Date[]>(null);

    const valid = !!runID && !!instrumentID;
    const {
      data: eventData,
      isLoading,
      refetch,
    } = useFetch(`/${fetchPrefix}/${runID}/events`, valid);

    const url =
      !!metric && !!fullRange && !!instrumentID
        ? "/instrument/" +
          instrumentID +
          "/systemStats/" +
          metric.sensor +
          "?start=" +
          fullRange[0].toISOString() +
          "&end=" +
          fullRange[1].toISOString()
        : null;

    const {
      data: metricData,
      isLoading: metricIsLoading,
      refetch: metricRefetch,
    } = useFetch(url, url !== null);

    useEffect(() => {
      if (isLoading) return;
      const runEvents = eventData as RunEvent[];
      if (runEvents) {
        if (runEvents.length === 0) {
          runEvents.push({
            sensor: "",
            name: "An hour ago",
            timestamp: new Date(new Date().getTime() - 60 * 60 * 1000),
            cycle: 0,
            read: "",
            batch: "",
          });
          runEvents.push({
            sensor: "",
            name: "Now",
            cycle: 0,
            read: "",
            timestamp: new Date(),
            batch: "",
          });
        } else if (runEvents.length === 1)
          runEvents.push({
            sensor: "",
            name: "Now",
            cycle: 0,
            read: "",
            timestamp: new Date(),
            batch: "",
          });

        setConstantLines(
          getLinesFromRunEvents(runEvents, showCycleLines, typeRun)
        );

        const lastDate = new Date(
          new Date(runEvents[runEvents.length - 1].timestamp).getTime() +
            1000 * 60 * 60 * 0.15
        );

        const tmprange = [new Date(runEvents[0].timestamp), lastDate];
        setFullRange(tmprange);
      } else {
        setConstantLines(null);
        setFullRange(null);
      }
    }, [chartRef, eventData, isLoading, showCycleLines, typeRun]);

    var loadData = useCallback(async () => {
      if (metricIsLoading) return [];
      return metricData;
    }, [metricIsLoading, metricData]);

    var customStore = useMemo(
      () =>
        new CustomStore({
          key: "id",
          load: loadData,
        }),
      [loadData]
    );

    useEffect(() => {
      chartRef.current && chartRef.current.instance.render();
    }, []);

    const ConstantLines = useMemo(() => {
      if (!constantLines || constantLines.length < 2 || !fullRange) return null;
      let prevValue = 0;
      let prevPos = "top";

      return constantLines
        .filter((v) => v.value >= fullRange[0] && v.value <= fullRange[1])
        .map((v) => {
          let pos = "top";
          if (v.value.getTime() - prevValue < 3 * 60000) {
            if (prevPos === "top") pos = "center";
            else if (prevPos === "center") pos = "bottom";
            else pos = "top";
          }
          prevPos = pos;
          prevValue = v.value.getTime();

          return (
            <ConstantLine
              key={v.key}
              width={2}
              value={v.value}
              color="brown"
              dashStyle="dash"
            >
              <Label verticalAlignment={pos} text={v.label} font={brownColor} />
            </ConstantLine>
          );
        });
    }, [constantLines, fullRange]);

    const HorizontalConstantLines = useMemo(() => {
      if (metric?.sensor === "TempStats") {
        return [
          <ConstantLine
            key="High"
            width={2}
            value={80}
            color="brown"
            dashStyle="dash"
          >
            <Label text="High" font={brownColor} />
          </ConstantLine>,

          <ConstantLine
            key="Critical"
            width={2}
            value={100}
            color="brown"
            dashStyle="dash"
          >
            <Label text="Critical" font={brownColor} />
          </ConstantLine>,
        ];
      }
      return [];
    }, [metric]);

    return (
      <div
        style={useMemo(() => ({ flex: 1, marginLeft: 50, marginTop: 40 }), [])}
      >
        {constantLines && constantLines.length > 0 && (
          <div style={{ marginBottom: 15 }}>
            Current Step: {constantLines[constantLines.length - 1].label}
          </div>
        )}
        <div style={{ display: "flex", width: 400 }}>
          <CheckBox
            value={showCycleLines}
            onValueChanged={(e) => setShowCycleLines(e.value)}
          />
          <span style={{ marginLeft: 8 }}>Show Cycle Lines</span>
        </div>
        <div
          style={{
            display: "flex",
            width: 400,
            alignItems: "center",
            marginTop: 8,
          }}
        >
          <span style={{ marginRight: 8 }}>Metric</span>
          <SelectBox
            value={metric}
            displayExpr="sensor"
            placeholder="Select a metric..."
            showClearButton={false}
            dataSource={metrics}
            onValueChanged={(e) => setMetric(e.value)}
            style={useMemo(() => ({ width: "300px" }), [])}
          />
        </div>
        <br />

        <Chart
          height={window.screen.height - 700}
          id="chart"
          ref={chartRef}
          palette="Soft Blue"
          dataSource={customStore}
          scrollBar={useMemo(() => ({ visible: true, position: "bottom" }), [])}
          onLegendClick={useCallback((e) => {
            const series = e.target;
            if (series.isVisible()) {
              series.hide();
            } else {
              series.show();
            }
          }, [])}
        >
          <ArgumentAxis
            argumentType={"datetime"}
            title={"Time"}
            wholeRange={fullRange}
          >
            <Grid visible={true} />
            {ConstantLines}
          </ArgumentAxis>

          <SeriesTemplate nameField="sensor" />

          <CommonSeriesSettings
            argumentField="timestamp"
            valueField="value"
            type="line"
          >
            <Aggregation enabled={false} />
            <Point size={4} />
          </CommonSeriesSettings>

          <ValueAxis title={metric.ylabel}>{HorizontalConstantLines}</ValueAxis>
          <Legend visible={true} />

          <ZoomAndPan argumentAxis="both" valueAxis="both" />
        </Chart>

        <LoadPanel
          // shadingColor="rgba(0,0,0,0.4)"
          position={{ of: "#chart" }}
          visible={metricIsLoading}
          showIndicator={true}
          //  shading={true}
          //  showPane={true}
        />

        <Button
          style={{ marginTop: 5 }}
          text="Refresh"
          icon="refresh"
          onClick={useCallback(() => {
            refetch();
            metricRefetch();
          }, [metricRefetch, refetch])}
        />
      </div>
    );
  }
);

export default RunSystemStats;
