import React, { memo, useMemo, useState, useRef } from "react";

import DataGrid, {
  Column,
  GroupPanel,
  HeaderFilter,
  Export,
  Paging,
  Item,
  Toolbar,
  StateStoring,
  SearchPanel,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";

import { useFetch } from "../../../hooks/useFetch";
import { Button } from "devextreme-react/button";
import { TypeRun } from "../types";

type RunEventsProps = {
  runID: string;
  typeRun: TypeRun;
  days: number;
};

export default memo(({ runID, typeRun, days }: RunEventsProps) => {
  const fetchPrefix = typeRun === "Spatial" ? "spatialRun" : "run";
  const [forceRefresh, setForceRefresh] = useState(false);
  const {
    data: events,
    isLoading,
    refetch,
  } = useFetch(
    `/${fetchPrefix}/${runID}/events` +
      (forceRefresh ? `?days=${days + 1}&forceRefresh` : ""),
    !!runID
  );
  const grid = useRef();

  const [refetchRequested, setRefetchRequested] = useState(false);

  const resetOptions = useMemo(() => {
    return {
      hint: "Reset to default table layout",
      text: "   Reset",
      stylingMode: "text",
      onClick: () => {
        (grid.current as any).instance.state({});
      },
    };
  }, []);

  const spacerOptions = useMemo(() => {
    return {
      hint: "",
      text: "",
      stylingMode: "icon",
      disabled: true,
    };
  }, []);

  return (
    <div>
      <LoadPanel
        position={{ of: "#thegrid" }}
        visible={isLoading || refetchRequested}
        showIndicator={true}
        showPane={true}
      />

      <Button
        style={{ marginTop: 5 }}
        text="Refresh from Snowflake"
        icon="refresh"
        onClick={() => {
          setForceRefresh(true);
          setRefetchRequested(true);
          refetch().finally(() => setRefetchRequested(false));
        }}
      />

      <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
        <DataGrid
          dataSource={events}
          ref={grid}
          id="thegrid"
          keyExpr="id"
          height="80vh"
          width="100%"
          allowColumnReordering={true}
          showBorders={true}
          selection={{ mode: "single" }}
          columnAutoWidth
        >
          <Export
            enabled
            fileName={"EventsTable"}
            allowExportSelectedData={true}
          />
          <GroupPanel visible />
          <HeaderFilter visible />
          <SearchPanel visible />
          <Paging enabled={true} pageSize={100} />
          <StateStoring
            enabled={false}
            type="localStorage"
            storageKey="eventsTable"
          />
          <Toolbar>
            <Item name="columnChooserButton" />
            <Item name="groupPanel" />
            <Item name="searchPanel" />
            <Item name="exportButton" />
            <Item location="before" widget="dxButton" options={spacerOptions} />
            <Item location="before" widget="dxButton" options={resetOptions} />
          </Toolbar>
          <Column dataField="name" dataType="string" />
          <Column
            dataField="timestamp"
            dataType={"date"}
            sortIndex={0}
            sortOrder="asc"
            format={" HH:mm:ss (MMM dd) "}
          />
          <Column dataField="cycle" />
          {typeRun === "Sequencing" && <Column dataField="read" />}
          {typeRun === "Spatial" && <Column dataField="batch" />}
        </DataGrid>
      </div>
    </div>
  );
});
