import pako from "pako";

export const decompressFloat32Array = (str: string): number[] => {
  try {
    const gezipedData = atob(str);
    const gzipedDataArray = Uint8Array.from(gezipedData, (c) =>
      c.charCodeAt(0)
    );
    const ungzipedData = pako.ungzip(gzipedDataArray);
    const f32 = new Float32Array(ungzipedData.buffer);
    return Array.from(f32);
  } catch {
    return [];
  }
};
