import { withNavigationWatcher } from "./contexts/navigation";
import {
  TasksPage,
  ProfilePage,
  InstrumentsPage,
  RunsPage,
  SpatialRunsPage,
  CustomerReportPage,
  InstallReportPage,
  ExecutiveDashboard,
  CustomerMapPage,
  InternalDashboard,
} from "./pages";
import InternalUtilization from "./pages/report/executive/internal-utilization";
import ExecutiveUtilization from "./pages/report/executive/utilization";
import { UtilizationPlanner } from "./pages/report/executive/utilization-planner";

const routes = [
  {
    path: "/tasks",
    component: TasksPage,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/instruments",
    component: InstrumentsPage,
  },
  {
    path: "/runs",
    component: RunsPage,
  },
  {
    path: "/spatialRuns",
    component: SpatialRunsPage,
  },
  {
    path: "/customer-report",
    component: CustomerReportPage,
  },
  {
    path: "/customer-map",
    component: CustomerMapPage,
  },
  {
    path: "/install-report",
    component: InstallReportPage,
  },
  {
    path: "/executive-dashboard",
    component: ExecutiveDashboard,
  },
  {
    path: "/customer-calendar",
    component: UtilizationPlanner,
  },
  {
    path: "/executive-utilization",
    component: ExecutiveUtilization,
  },
  {
    path: "/executive-internal-utilization",
    component: InternalUtilization,
  },
];

export default routes.map((route) => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
