import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import { useEffect } from "react";
import { HashRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import Privacy from "./Privacy";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";
const queryClient = new QueryClient();

const App = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth();

  // const { data: instruments } = useFetch("get-instruments", "/instrument");

  useEffect(() => {
    if (!isLoading && !isAuthenticated) loginWithRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  // useEffect(() => {
  //   if (instruments) {
  //     observableStore.addRuns(instruments);
  //   }
  // });

  if (isLoading) {
    return <LoadPanel visible={true} />;
  }

  return <Content />;
};

const AppContainer = () => {
  const screenSizeClass = useScreenSizeClass();

  if (window.location.href.endsWith("/privacy")) return <Privacy />;

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <QueryClientProvider client={queryClient}>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </QueryClientProvider>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
};

export default AppContainer;
