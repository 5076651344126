import React, {
    useState,
    memo,
    useMemo,
    useCallback,
    useEffect,
  } from "react";
  import { Button } from "devextreme-react/button";
  import { useFetch } from "../../../hooks/useFetch";
  import { LoadPanel } from "devextreme-react/load-panel";

  
 
  export type RunMetricProps = {
    instrumentID: string;
    runID: string;
  };

  type RunEvent = {
    name: string;
    timestamp: Date;
    sensor: string;
    cycle: number;
    read: string;
  };
  
  const cardStyles = {
    "Success": {
        padding: 20,
        margin: 10,
        backgroundColor: '#cfe8b5',
        borderColor: '#79cf1d',
        borderRadius: '20px'
    },
    "Warning": {
        padding: 20,
        margin: 10,
        backgroundColor: '#ffe082',
        borderColor: '#ffbf00',
        borderRadius: '20px'
    },
    "Info": {
        padding: 20,
        margin: 10,
        backgroundColor: '#d1fdff',
        borderColor: '#00a2ff',
        borderRadius: '20px'
    },
    "Error": {
        padding: 20,
        margin: 10,
        backgroundColor: '#ffbfbf',
        borderColor: '#d40000',
        borderRadius: '20px'
    }
  }

  const RunNotifications = memo(({ instrumentID, runID }: RunMetricProps) => {
    const [fullRange, setFullRange] = useState<Date[]>(null);
    const valid = !!runID && !!instrumentID;
    const {
      data: eventData,
      isLoading,
      refetch,
    } = useFetch(`/run/${runID}/events`, valid);

    const url =
       !!fullRange && !!instrumentID
        ? "/instrument/" +
          instrumentID +
          "/notifications/" + runID +
          "?start=" +
          fullRange[0].toISOString() +
          "&end=" +
          fullRange[1].toISOString()
        : null;
    console.log(url);

    const {
      data: notificationsData,
      isLoading: notificationIsLoading,
      refetch: notificationRefetch,
    } = useFetch(url, url !== null);
    
    console.log(notificationsData)

    useEffect(() => {
        if (isLoading) return;
        const runEvents = eventData as RunEvent[];
        if (runEvents) {
          if (runEvents.length === 0) {
            runEvents.push({
              sensor: "",
              name: "An hour ago",
              timestamp: new Date(new Date().getTime() - 60 * 60 * 1000),
              cycle: 0,
              read: "",
            });
            runEvents.push({
              sensor: "",
              name: "Now",
              cycle: 0,
              read: "",
              timestamp: new Date(),
            });
          } else if (runEvents.length === 1)
            runEvents.push({
              sensor: "",
              name: "Now",
              cycle: 0,
              read: "",
              timestamp: new Date(),
            });
    
          let lines = [];
    
          for (let i = 0; i < runEvents.length; i++) {
            if (
              [
                "SequencingRunInitialized",
                "Priming",
                "Primed",
                "Sequencing",
                "ReadStarted",
                "CycleStarted",
                //       "TrapStarted",
                // "ImagingStarted",
                // "ImagingFinished",
                //        "CleaveStarted",
                //        "IncorpStarted",
                //        "CycleFinished",
                "ReadFinished",
                "WashStarted",
                "WashFinished",
                "RecipeUpload_Completed",
                "Finished",
              ].indexOf(runEvents[i].name) >= 0
            )
              lines.push({
                key: `event${i}`,
                value: new Date(runEvents[i].timestamp),
                label:
                  runEvents[i].name === "CycleStarted"
                    ? `${runEvents[i].read} C${runEvents[i].cycle}`
                    : runEvents[i].name,
              });
          }
          console.log(runEvents);
          const lastDate = new Date(
            new Date(runEvents[runEvents.length - 1].timestamp).getTime() +
              1000 * 60 * 60 * 0.15
          );
    
          const tmprange = [new Date(runEvents[0].timestamp), lastDate];
          console.log(tmprange);
          setFullRange(tmprange);
        } else {
          setFullRange(null);
        }
      }, [eventData, isLoading]);
    
    return (
    <div style={useMemo(() => ({ flex: 1, marginLeft: 50, marginTop: 40 }), [])}>
      <div id="runNotifications">
        {notificationsData?.length <= 0 && !notificationIsLoading 
          ? <div style={cardStyles.Info}> There are currently no notifications for this run.</div>
          : notificationsData?.reverse().map((notification) => {
              return <div key={"notificationID-"+notification.id} style={cardStyles[notification.type]}>
                  <h6 style={{margin: 5}}>{notification.subject}</h6>
                  <span style={{margin: 5}}>{notification.body}</span>
                  <span style={{float: 'right', fontSize: 10, paddingTop: 3}}>{new Date(notification.timeCreated).toString()}</span>
              </div>
          })
        }
      </div>

      <LoadPanel
        position={{ of: "#runNotifications" }}
        visible={notificationIsLoading && isLoading}
        showIndicator={true}
      />
       
        <Button
          style={{ marginTop: 5 }}
          text="Refresh"
          icon="refresh"
          onClick={useCallback(() => {
            refetch();
            notificationRefetch();
          }, [notificationRefetch, refetch])}
        />
      </div>
    );
  });
  
  export default RunNotifications;
  