import { useMemo, useState, useEffect } from "react";
import { Run } from "../types";
import { GrayscaleImage } from "src/components/GrayscaleImage";
import { SelectBox } from "devextreme-react";
import { useAuth } from "src/contexts/auth";
import { fetchWithToken } from "src/hooks/useFetch";

type RunLogsProps = {
  run: Run;
};

const bucket = "elembio-prod-usw2-s3-aviti-telemetry-logs-usw2";

// const thumbnailNames = [
//   "R1_C001_Center.tif",
//   "R1_C001_Full.tif",
//   "R1_C002_Center.tif",
//   "R1_C002_Full.tif",
//   "R1_C003_Center.tif",
//   "R1_C003_Full.tif",
//   "R1_C004_Center.tif",
//   "R1_C004_Full.tif",
//   "R1_C005_Center.tif",
//   "R1_C005_Full.tif",
// ];

const RunThumbnails = ({ run }: RunLogsProps) => {
  const [thumbnailName, setThumbnailName] = useState(null);
  const { isLoading, getAccessTokenSilently } = useAuth();
  const [thumbnailNames, setThumbnailNames] = useState([]);

  const runFolderPrefix = useMemo(() => {
    let tenantID = run?.tenantID;
    if (tenantID === "") tenantID = "ten_traefikee";
    return `avitiinternallogs/${tenantID}/${run?.instrumentID}_${run?.instrumentSerialNumber}_${run?.instrumentName}/runs/${run?.runID}`;
  }, [run]);

  const prefix = useMemo(() => {
    if (!thumbnailName) return null;
    let tenantID = run?.tenantID;
    if (tenantID === "") tenantID = "ten_traefikee";
    return `/${runFolderPrefix}/FlowcellThumbnail/${thumbnailName}`;
  }, [run, runFolderPrefix, thumbnailName]);

  useEffect(() => {
    if (isLoading) return;

    const req = {
      Path: "FlowcellThumbnail",
      Bucket: bucket,
      Prefix: runFolderPrefix,
    };
    console.log("thumbnail request", req);
    fetchWithToken(getAccessTokenSilently, "/run/logs", {
      method: "POST",
      body: JSON.stringify(req),
    })
      .then((results) => {
        console.log("results", results);
        setThumbnailNames(results.map((x) => x.name));
      })
      .catch(() => alert("An error occurred while trying to list thumbnails"));
  }, [runFolderPrefix, getAccessTokenSilently, isLoading]);

  return (
    <div id="parentDivList" style={{ marginLeft: 30, marginRight: "auto" }}>
      <div style={{ display: "flex", marginBottom: 20, marginTop: 20 }}>
        <span style={{ paddingTop: 5, paddingRight: 40, paddingBottom: 10 }}>
          Thumbnail to display:
        </span>

        <SelectBox
          items={thumbnailNames}
          value={thumbnailName}
          onValueChanged={(e) => setThumbnailName(e.value)}
        />
      </div>
      <GrayscaleImage imageURL={prefix ? `s3://${bucket}/${prefix}` : null} />
    </div>
  );
};

export default RunThumbnails;
