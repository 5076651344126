import { memo, useState, useEffect, useMemo } from "react";

import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";

import { GenericHeatmap } from "src/components/GenericHeatmap";
import { SelectBox } from "devextreme-react";
import { BusyOverlay } from "src/components/BusyOverlay";

var jwtDecode = require("jwt-decode");

// week 0
const startDate = new Date(2022, 5, 12, 18, 0, 0);

const getWeekString = (weeks) => {
  if (weeks === 1) return "Week";
  return `Week (${weeks} week avg)`;
};

export default memo(() => {
  const { getAccessTokenSilently } = useAuth();
  const [utilizationData, setUtilizationData] = useState([[]]);
  const [last4WeeksUtilization, setLast4WeeksUtilization] = useState([]);
  const [sortBy, setSortBy] = useState("Name");

  const [utilizationRowLabels, setUtilizationRowLabels] = useState([]);
  const [hasPermission, setHasPermission] = useState(null);
  const [instruments, setInstruments] = useState(null);
  const [runs, setRuns] = useState(null);
  const [weeksInBin, setWeeksInBin] = useState(1);
  const [ready, setReady] = useState(false);

  const getSortedIndices = (arr) => {
    if (!arr || arr.length === 0) return [];
    // Create an array of indices [0, 1, 2, ...]
    const indices = Array.from(arr.keys());

    if (sortBy === "Name") return indices;
    // Sort the indices array based on the corresponding values in the input array
    indices.sort((a, b) => arr[a] - arr[b]);
    return indices;
  };

  const sortArrayByIndices = (array, indices) => {
    if (sortBy === "Name") return array;
    if (!indices || indices.length === 0) return indices;
    return indices.map((index) => array[index]);
  };

  useEffect(() => {
    fetchWithToken(getAccessTokenSilently, "/instrument?internal").then(
      (instr) => {
        setInstruments(instr);
      }
    );

    fetchWithToken(getAccessTokenSilently, "/run?internal")
      .then((r) => setRuns(r))
      .finally(() => setReady(true));
  }, [getAccessTokenSilently]);

  const maxWeek = useMemo(() => {
    var dt = new Date();
    const days = Math.floor((dt - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.floor(days / 7);
    return weekNumber;
  }, []);

  useEffect(() => {
    if (!runs || !instruments || maxWeek === 0) return;

    const instrTenantMap = new Map();
    for (let i = 0; i < runs.length; i++) {
      const key = `${runs[i].instrumentSerialNumber}`;
      if (!instrTenantMap.get(key)) {
        instrTenantMap.set(key, runs[i].instrumentSerialNumber);
      }
    }

    const sortedInstrumentKeys = Array.from(instrTenantMap.keys()).sort();

    const instrUtilizationByWeek = [];

    const instrMap = new Map();

    const rows = [];

    for (let i = 0; i < sortedInstrumentKeys.length; i++) {
      const key = sortedInstrumentKeys[i];
      const arr = [];
      for (let w = 0; w <= maxWeek; w++) arr.push(0);
      instrUtilizationByWeek.push(arr);
      instrMap.set(instrTenantMap.get(key), i);
      rows.push(key.replaceAll("ten-", ""));
    }

    for (let i = 0; i < runs.length; i++) {
      const run = runs[i];

      const instrIdx = instrMap.get(run.instrumentSerialNumber);

      if (instrIdx !== 0 && !instrIdx) {
        continue;
      }

      const ibw = instrUtilizationByWeek[instrIdx];
      const r0 = new Date(runs[i].startTime);
      let r1 = new Date(runs[i].endTime);
      if (r1 < r0 && new Date().getTime() - r0.getTime() < 50 * 1000 * 60 * 60)
        r1 = new Date();

      if (r1 > r0) {
        const w0 = Math.floor((r0 - startDate) / (7 * 24 * 60 * 60 * 1000));
        const w1 = Math.floor((r1 - startDate) / (7 * 24 * 60 * 60 * 1000));
        // if (runs[i].runName === "20220614_MichaelB_ElemDemo1") {
        //   debugger;
        //   console.log("Hi");
        // }
        const tw1 = startDate.getTime() + w1 * 7 * 24 * 60 * 60 * 1000;
        if (w0 === w1) {
          ibw[w0] += (r1 - r0) / (1000 * 60 * 60);
        } else {
          ibw[w0] += (tw1 - r0) / (1000 * 60 * 60);
          ibw[w1] += (r1 - tw1) / (1000 * 60 * 60);
        }
      }
    }

    const heatmapUtilizationData = [];
    const utilSum = [];

    for (let i = 0; i < instrUtilizationByWeek.length; i++) {
      const arr = [];
      let foundOne = false;
      let sum = 0;
      for (let w = 0; w <= maxWeek; w++) {
        const val = (100 * instrUtilizationByWeek[i][w]) / (7 * 24 * 2);
        if (val) {
          if (w >= maxWeek - 8) sum -= val;
          arr.push({
            val,
            label: val.toFixed(0),
          });
          foundOne = true;
        } else {
          let tmp = 0;
          if (!foundOne) tmp = NaN;
          arr.push({
            val: tmp,
            label: isNaN(tmp) ? "" : "0",
          });
        }
      }

      utilSum.push(sum);

      // compute average by binned weeks
      if (weeksInBin > 1) {
        for (let w = maxWeek; w >= 0; w--) {
          let sum = 0;
          let count = 0;
          for (let kw = w; kw > w - weeksInBin; kw--) {
            if (kw >= 0 && !isNaN(arr[kw].val)) {
              sum += arr[kw].val;
              count++;
            }
          }
          if (count > 0) {
            arr[w].val = sum / count;
            arr[w].label = (sum / count).toFixed(0);
          }
        }
      }
      heatmapUtilizationData.push(arr);
    }

    setLast4WeeksUtilization(utilSum);

    setUtilizationData(heatmapUtilizationData);
    setUtilizationRowLabels(rows);
  }, [instruments, runs, maxWeek, weeksInBin]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      var decoded = jwtDecode(token);
      const groups = decoded["https://elembio.io/user_groups"];
      if (groups.includes("Telemetry-ExecutivePermissions")) {
        setHasPermission(true);
      } else {
        setHasPermission(false);
      }
    });
  }, [getAccessTokenSilently]);

  if (hasPermission === null) {
    return <div>Checking permissions...</div>;
  }
  if (hasPermission === false) {
    return <div>You do not have permission</div>;
  }

  const indexOrder = getSortedIndices(last4WeeksUtilization);

  // The latest datagrid implementation with embedded refresh toolbar icon
  return (
    <div style={{ paddingLeft: 80, background: "white", height: "100%" }}>
      <div style={{ marginLeft: 30, paddingTop: 50, display: "flex" }}>
        <table>
          <tr>
            <td># Weeks in Bin</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>
              <SelectBox
                items={[1, 2, 4, 12, 36]}
                defaultValue={1}
                onValueChanged={(e) => {
                  setWeeksInBin(e.value);
                }}
              />
            </td>
          </tr>
        </table>

        <table style={{ marginLeft: 40 }}>
          <tr>
            <td>Sort by:</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>
              <SelectBox
                items={["Usage", "Name"]}
                defaultValue="Name"
                onValueChanged={(e) => {
                  setSortBy(e.value);
                }}
              />
            </td>
          </tr>
        </table>
      </div>

      <div
        style={{
          marginLeft: 80,
          marginTop: 30,
          height: "100%",
        }}
      >
        <GenericHeatmap
          title={`Utilization by ${getWeekString(weeksInBin)} (%)`}
          data={sortArrayByIndices(utilizationData, indexOrder)}
          rowlabels={sortArrayByIndices(utilizationRowLabels, indexOrder)}
          minv={0}
          maxv={100}
          tooltipprecision={1}
          hidelegend={false}
          scale={2}
          nogap
          maxH={"75%"}
          zeroAsWhite
        />
      </div>

      {!ready && <BusyOverlay />}
    </div>
  );
});
