import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import { Button, SelectBox } from "devextreme-react";
import { Run } from "../types";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

import {
  Chart,
  CommonSeriesSettings,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  ArgumentAxis,
  Tooltip,
  ZoomAndPan,
} from "devextreme-react/chart";

type RunQualityProps = {
  run: Run;
};

type BaseComposition = {
  PercentUnknown: number;
  PercentA: number;
  PercentC: number;
  PercentG: number;
  PercentT: number;
};

type CycleMetrics = {
  Read: string;
  Cycle: number;
  ErrorRate: number;
  AverageQScore: number;
  PercentQ30: number;
  PercentQ40: number;
  BaseComposition: BaseComposition;
};

type DataElement = {
  Group: string;
  Cycle: number;
  Value: number;
  Label: string;
};

const RunBaseComposition = memo(({ run }: RunQualityProps) => {
  const chart = useRef();
  const containerDiv = useRef();

  const [data, setData] = useState<DataElement[]>([]);
  const [libraryPoolDisplay, setLibraryPoolDisplay] = useState<"Run Level" | "Library Pool 1" | "Library Pool 2" >(
    "Run Level"
  );

  const {
    data: runMetricsData,
    isLoading,
    refetch,
  } = useFetch(`/run/${run?.runID}/runMetrics`, !!run?.runID);

  console.log("run metrics", runMetricsData);

  const cycleMetrics: CycleMetrics[] = libraryPoolDisplay === "Run Level"
  ? runMetricsData?.RunMetrics.CycleMetrics 
  : libraryPoolDisplay === "Library Pool 1"
  ? runMetricsData?.LaneMetrics[0]?.CycleMetrics 
  : runMetricsData?.LaneMetrics[1]?.CycleMetrics;

  useEffect(() => {
    if (cycleMetrics) {
      const justReads = cycleMetrics.map((x) => x.Read);
      const tmp = [...Array.from(new Set<string>(justReads))];

      let { readOrder } = run;

      if (readOrder.indexOf(",") === -1 && readOrder.length === 8) {
        readOrder =
          readOrder.substring(0, 2) +
          "," +
          readOrder.substring(2, 4) +
          "," +
          readOrder.substring(4, 6) +
          "," +
          readOrder.substring(6);
      }
      const sortOrder = readOrder.split(",");
      let distinctReads = [];
      for (var j = 0; j < sortOrder.length; j++) {
        if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
      }
      var maxCycleForRead = {};
      for (j = 0; j < distinctReads.length; j++) {
        maxCycleForRead[distinctReads[j]] = 0;
      }

      for (j = 0; j < cycleMetrics.length; j++) {
        if (cycleMetrics[j].Cycle > maxCycleForRead[cycleMetrics[j].Read]) {
          maxCycleForRead[cycleMetrics[j].Read] = cycleMetrics[j].Cycle;
        }
      }
      var readCycleOffset = {};
      readCycleOffset[distinctReads[0]] = 0;
      var currentOffset = 0;
      for (j = 0; j < distinctReads.length; j++) {
        readCycleOffset[distinctReads[j]] = currentOffset;
        currentOffset += maxCycleForRead[distinctReads[j]];
      }

      const values: DataElement[] = [];

      for (let i = 0; i < cycleMetrics.length; i++) {
        const cm = cycleMetrics[i];
        if (cm.Read.startsWith("P")) continue;
        values.push({
          Cycle: cm.Cycle + readCycleOffset[cm.Read],
          Value: cm.BaseComposition?.PercentA,
          Group: `${cm.Read} Percent A`,
          Label: `${cm.Read} C${cm.Cycle}`,
        });
        values.push({
          Cycle: cm.Cycle + readCycleOffset[cm.Read],
          Value: cm.BaseComposition?.PercentC,
          Group: `${cm.Read} Percent C`,
          Label: `${cm.Read} C${cm.Cycle}`,
        });
        values.push({
          Cycle: cm.Cycle + readCycleOffset[cm.Read],
          Value: cm.BaseComposition?.PercentG,
          Group: `${cm.Read} Percent G`,
          Label: `${cm.Read} C${cm.Cycle}`,
        });
        values.push({
          Cycle: cm.Cycle + readCycleOffset[cm.Read],
          Value: cm.BaseComposition?.PercentT,
          Group: `${cm.Read} Percent T`,
          Label: `${cm.Read} C${cm.Cycle}`,
        });
      }
      setData(values);
    } else {
      setData([]);
    }
  }, [cycleMetrics, run]);

  console.log("data", data);

  useEffect(() => {
    if (chart.current && data)
      (chart.current as any).instance.resetVisualRange();
  }, [chart, data]);

  useEffect(() => {
    chart.current && (chart.current as any).instance.render();
  }, []);

  const renderTooltip = useCallback((pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label3 = data.Label;
      return (
        <div>
          {data.Group}
          <br />
          {pointInfo.value.toPrecision(4)}%
          <br />
          {label3}
        </div>
      );
    }
    return <div />;
  }, []);

  return (
    <div ref={containerDiv} style={{ marginTop: 40 }}>
      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={["Run Level", "Library Pool 1", "Library Pool 2"]}
              value={libraryPoolDisplay}
              onValueChanged={(e) => {
                setLibraryPoolDisplay(e.value);
              }}
            />
          </td>
        </tr>
      </table>
      
      <LoadPanel
        position={{ of: "#chart" }}
        visible={isLoading}
        showIndicator={true}
        showPane={true}
      />

      <Chart
        id="chart"
        ref={chart}
        height="70vh"
        onLegendClick={useCallback((e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }, [])}
        dataSource={data}
      >
        <CommonSeriesSettings
          argumentField="Cycle"
          valueField="Value"
          type="line"
        >
          <Point size={4} />
        </CommonSeriesSettings>
        <SeriesTemplate nameField="Group" />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis title="%" wholeRange={[0, 100]} />
        <ArgumentAxis title="Cycle" allowDecimals={true} />
        <Legend
          visible={true}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />
      </Chart>
      <Button
        style={{ marginTop: 5 }}
        text="Refresh"
        icon="refresh"
        onClick={() => {
          refetch();
        }}
      />
    </div>
  );
});

export default RunBaseComposition;
