import { useRef, memo, useMemo, useCallback, useState } from "react";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Export,
  ColumnChooser,
  Paging,
  SearchPanel,
  Summary,
  Format,
  GroupItem,
  FilterRow,
  Pager,
  Editing,
  Item,
  TotalItem,
  Toolbar,
  StateStoring,
} from "devextreme-react/data-grid";

import PieChart, {
  Series,
  Label,
  Connector,
  Size,
} from "devextreme-react/pie-chart";

import { Link } from "react-router-dom";

import { useFetch } from "../../hooks/useFetch";

import CustomStore from "devextreme/data/custom_store";
import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";

const groupCellTemplate = (elem, data) => {
  var formattedValue = `${data.displayValue} (${data.data.aggregates} runs)   ${
    data.groupContinuesMessage ? `  (${data.groupContinuesMessage})` : ""
  }`;
  elem.append(formattedValue);
};

const pagesizes = [20, 100, "all"];

const colfixing = { enabled: true };

const customizedText = (e) => {
  if (isNaN(e.value) || e.value <= 0) return "";
  return e.valueText;
};

const cellRenderRunName = (cell) => {
  if (!cell.value) {
    return <div />;
  }
  return <Link to={"/runs?r=" + cell.data.runID + "&t=0"}>{cell.text}</Link>;
};

const customizedDateText = (e) => {
  if (e.value.getFullYear() < 2020) return "";
  return e.valueText;
};

const cellPrepared = (e) => {
  if (e.rowType !== "data") return;
  if (e.column.name === "q30") {
    if (e.value > 0) {
      if (e.value < 0.8) e.cellElement.style.backgroundColor = "red";
      else if (e.value < 0.85) e.cellElement.style.backgroundColor = "orange";
      else if (e.value >= 0.85) e.cellElement.style.backgroundColor = "green";
      e.cellElement.style.color = "white";
    }
  }
  if (e.column.name === "poloniesPF") {
    if (e.value > 0) {
      if (e.value >= 800) e.cellElement.style.backgroundColor = "green";
      else if (e.value < 800) e.cellElement.style.backgroundColor = "red";
      e.cellElement.style.color = "white";
    }
  }

  if (e.column.name === "indexAssignmentPercent") {
    if (e.value > 0) {
      if (e.value >= 95) e.cellElement.style.backgroundColor = "green";
      else if (e.value >= 90) e.cellElement.style.backgroundColor = "orange";
      else if (e.value < 90) e.cellElement.style.backgroundColor = "red";
      e.cellElement.style.color = "white";
    }
  }

  if (e.column.name === "runTime") {
    if (e.value > 0) {
      if (e.value <= 48) e.cellElement.style.backgroundColor = "green";
      else if (e.value <= 50) e.cellElement.style.backgroundColor = "orange";
      else if (e.value > 50) e.cellElement.style.backgroundColor = "red";
      e.cellElement.style.color = "white";
    }
  }

  if (e.column.name === "successfulRun") {
    if (e.value === "YES") e.cellElement.style.backgroundColor = "green";
    else if (e.value === "NO") e.cellElement.style.backgroundColor = "red";
    else e.cellElement.style.backgroundColor = "gray";
    e.cellElement.style.color = "white";
  }
};

export default memo((props) => {
  const { getAccessTokenSilently } = useAuth();
  const grid = useRef();
  const [overallSuccess, setOverallSuccess] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const { data: allInstruments } = useFetch("/instrument");

  const instruments = useMemo(() => {
    if (!allInstruments) return [];
    return allInstruments.filter((v) => v.instrumentName !== "AV223001");
  }, [allInstruments]);

  const loadData = useCallback(async () => {
    const runs = await fetchWithToken(
      getAccessTokenSilently,
      "/run?filter=install"
    );
    if (!runs) return [];
    const origvals = runs;
    //runs.filter((v) =>
    //  v.runName?.toUpperCase().includes("INSTALL")
    // );

    for (let i = 0; i < origvals.length; i++) {
      const inst = instruments.find(
        (inst) => inst.instrumentID === origvals[i].instrumentID
      );

      if (!inst) {
        origvals[i].tenantID = null;
      } else origvals[i].tenantID = inst?.tenantID;
    }

    if (!origvals) return [];

    const vals = origvals.filter((x) => x.tenantID !== null);

    const instrumentMap = new Map();

    let successes = 0;
    let failures = 0;
    let tbd = 0;
    for (let i = 0; i < vals.length; i++) {
      const instrumentName = vals[i].instrumentName;
      if (!instrumentMap.get(instrumentName))
        instrumentMap.set(instrumentName, {
          nickname: vals[i].instrumentNickname,
          runs: 0,
          success: 0,
          failed: 0,
          tbd: 0,
        });
      const instrumentEntry = instrumentMap.get(instrumentName);
      instrumentEntry.runs++;
      vals[
        i
      ].runLength = `${vals[i].r1}+${vals[i].r2},${vals[i].i1}+${vals[i].i2}`;
      vals[i].successfulRun =
        vals[i].q30 >= 0.9 &&
        vals[i].poloniesPF >= 800 &&
        vals[i].runTime <= 48 &&
        (vals[i].indexAssignmentPercent < 0 ||
          vals[i].indexAssignmentPercent >= 90)
          ? "YES"
          : "NO";

      if (
        !(
          vals[i].lastStatus.startsWith("Failed") ||
          vals[i].lastStatus === "Finished" ||
          vals[i].lastStatus === "RecipeUpload_Completed"
        )
      ) {
        vals[i].successfulRun = "TBD";
      }

      if (vals[i].successfulRun === "YES") {
        instrumentEntry.success++;
      } else if (vals[i].successfulRun === "NO") {
        instrumentEntry.failed++;
      } else {
        instrumentEntry.tbd++;
        tbd++;
      }
    }

    const tableEntries = [];
    instrumentMap.forEach((value, key) => {
      if (value.runs === 2) successes++;
      else failures++;
      tableEntries.push({
        name: key,
        ...value,
        firstPassSuccess: value.runs === 2,
      });
    });

    setOverallSuccess([
      { label: "Success", value: successes },
      { label: "Failed", value: failures },
      { label: "TBD", value: tbd / 2 },
    ]);

    setTenantData(tableEntries);

    return vals;
  }, [getAccessTokenSilently, instruments]);

  var updateData = useCallback(
    async (key, values) => {
      await fetchWithToken(getAccessTokenSilently, "/run/" + key, {
        method: "PATCH",
        body: JSON.stringify(values),
      });
    },
    [getAccessTokenSilently]
  );

  const refreshOptions = useMemo(() => {
    return {
      icon: "refresh",
      hint: "Refresh",
      text: "",
      stylingMode: "icon",
      onClick: () => {
        if (grid) grid.current.instance.refresh();
      },
    };
  }, []);

  const resetOptions = useMemo(() => {
    return {
      hint: "Reset to default table layout",
      text: "   Reset",
      stylingMode: "text",
      onClick: () => {
        grid.current.instance.state({});
      },
    };
  }, []);

  const spacerOptions = useMemo(() => {
    return {
      hint: "",
      text: "",
      stylingMode: "icon",
      disabled: true,
    };
  }, []);

  var customStore = useMemo(
    () =>
      new CustomStore({
        key: "runID",
        load: loadData,
        update: updateData,
      }),
    [loadData, updateData]
  );

  // The latest datagrid implementation with embedded refresh toolbar icon
  return (
    <>
      <div style={{ display: "flex", minHeight: 300 }}>
        <DataGrid
          dataSource={tenantData}
          showBorders={true}
          showRowLines={true}
          showColumnLines={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          columnResizingMode={"widget"}
          columnFixing={{ enabled: true }}
          wordWrapEnabled
        >
          <Summary>
            <TotalItem column="name" displayFormat="Total" />
            <TotalItem column="runs" summaryType="sum" displayFormat="{0}" />
            <TotalItem column="success" summaryType="sum" displayFormat="{0}" />
            <TotalItem column="failed" summaryType="sum" displayFormat="{0}" />
          </Summary>
        </DataGrid>
        <PieChart
          title={`First Pass Success ${useMemo(() => {
            if (!overallSuccess || overallSuccess.length !== 3) return "";
            return (
              (overallSuccess[0].value * 100) /
              (overallSuccess[0].value + overallSuccess[1].value)
            ).toFixed(0);
          }, [overallSuccess])}%`}
          dataSource={overallSuccess}
          style={{ marginLeft: 50 }}
          customizePoint={(e) => {
            if (e.argument === "Success") return { color: "green" };
            else if (e.argument === "Failed") return { color: "red" };
            else return { color: "gray" };
          }}
        >
          <Series argumentField="label" valueField="value">
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>

          <Size width={500} />
        </PieChart>
      </div>

      <div id="parentDivList" style={{ margin: 10 }}>
        <DataGrid
          ref={grid}
          className="mygrid"
          dataSource={customStore}
          width="100%"
          height="90vh"
          keyExpr="runID"
          wordWrapEnabled
          showBorders
          showColumnLines
          showRowLines
          allowColumnResizing
          allowColumnReordering
          columnResizingMode="widget"
          columnHidingEnabled
          columnAutoWidth
          columnFixing={colfixing}
          onCellPrepared={cellPrepared}
        >
          <Export
            enabled={true}
            fileName={"CustomerReport"}
            allowExportSelectedData={true}
          />

          <ColumnChooser enabled={true} />
          <GroupPanel visible />
          <SearchPanel visible />
          <Grouping autoExpandAll />
          <FilterRow visible />
          {/* <Selection mode={"single"} /> */}
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey="installReportTable"
          />

          <Toolbar>
            <Item location="after" widget="dxButton" options={refreshOptions} />
            <Item name="exportButton" />
            <Item name="columnChooserButton" />
            <Item name="groupPanel" />
            <Item name="searchPanel" />
            <Item location="before" widget="dxButton" options={spacerOptions} />
            <Item location="before" widget="dxButton" options={resetOptions} />
          </Toolbar>

          <Column
            dataField="tenantID"
            fixed
            width={150}
            allowEditing={false}
            caption="Customer Tenant"
          />
          <Column
            dataField="instrumentName"
            caption="instrument"
            fixed
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
            dataField="instrumentNickname"
            fixed
            dataType="string"
            groupCellTemplate={groupCellTemplate}
            allowEditing={false}
          />
          <Column
            fixed
            dataField="runName"
            dataType="string"
            allowEditing={false}
            cellRender={cellRenderRunName}
          />
          <Column dataField="side" allowEditing={false} />
          <Column
            dataField="Date"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            allowEditing={false}
          />

          <Column
            dataField="lastStatusUpdated"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            customizeText={customizedDateText}
            allowEditing={false}
          />
          <Column dataField="lastStatus" allowEditing={false} />

          <Column dataField="version" allowEditing={false} />
          <Column dataField="runID" visible={false} allowEditing={false} />
          <Column
            dataField="runFolderName"
            visible={false}
            allowEditing={false}
          />
          <Column dataField="prepType" allowEditing={false} />
          <Column dataField="kitType" visible={false} allowEditing={false} />
          <Column dataField="readOrder" visible={false} allowEditing={false} />
          <Column dataField="r1" visible={false} allowEditing={false} />
          <Column dataField="r2" visible={false} allowEditing={false} />
          <Column dataField="i1" visible={false} allowEditing={false} />
          <Column dataField="i2" visible={false} allowEditing={false} />
          <Column dataField="runLength" allowEditing={false} />
          <Column
            dataField="q30"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="percent" precision={2} />
          </Column>
          <Column
            dataField="error"
            allowEditing={false}
            customizeText={customizedText}
          >
            <Format type="percent" precision={4} />
          </Column>
          <Column
            dataField="poloniesRaw"
            caption="Polonies Raw (M)"
            customizeText={customizedText}
            allowEditing={false}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="poloniesPF"
            caption="Polonies PF (M)"
            customizeText={customizedText}
            allowEditing={false}
          >
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Column
            dataField="indexAssignmentPercent"
            caption="Index Assignment (%)"
            customizeText={customizedText}
            allowEditing={true}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column
            dataField="runTime"
            caption="Run Time (Hrs)"
            allowEditing={false}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column
            dataField="startTime"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            allowEditing={false}
          />
          <Column
            dataField="endTime"
            dataType={"date"}
            format={"MMM dd yyyy HH:mm"}
            allowEditing={false}
          />
          <Column dataField="numTiles" visible={false} allowEditing={false} />

          <Column
            dataField="flowcellSerialNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="flowcellPartNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="flowcellLotNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="flowcellExpiration"
            dataType={"date"}
            format={"MMM dd yyyy"}
            visible={false}
            allowEditing={false}
          />

          <Column
            dataField="reagentSerialNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="reagentPartNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="reagentLotNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="reagentExpiration"
            dataType={"date"}
            format={"MMM dd yyyy"}
            visible={false}
            allowEditing={false}
          />

          <Column
            dataField="bufferSerialNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="bufferPartNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="bufferLotNumber"
            visible={false}
            allowEditing={false}
          />
          <Column
            dataField="bufferExpiration"
            dataType={"date"}
            format={"MMM dd yyyy"}
            visible={false}
            allowEditing={false}
          />
          <Column dataField="description" width={300} allowEditing={false} />
          <Column dataField="successfulRun" />
          <Column dataField="runNotes" width={300} />

          <Summary>
            <GroupItem
              column="instrumentName"
              summaryType="count"
              displayFormat="{0} runs"
            />
          </Summary>

          <Paging defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={pagesizes}
            showInfo={true}
            showNavigationButtons={true}
          />

          <Editing
            mode="cell" //"form" "batch" "popup" "cell"
            allowUpdating={true}
            allowAdding={false}
            allowDeleting={false}
          />
        </DataGrid>
      </div>
    </>
  );
});
