import { useState, useEffect } from "react";

import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";
import { GenericHeatmap } from "src/components/GenericHeatmap";

interface UtilizationForInstrument {
  tenant: string;
  serialNumber: string;
  utilization: number[];
}

interface HeatmapElement {
  val: number;
  label: string;
}
interface HeatmapData {
  rowLabels: string[];
  data: HeatmapElement[][];
}

const cleanTenantName = (name: string): string => {
  return name
    .substring(4)
    .replaceAll("-35dbeb9f-b8f5-4d9d-8423-a1209452f18c", "");
};

const ExecutiveUtilization = () => {
  const { getAccessTokenSilently } = useAuth();

  const [heatmapData, setHeatmapData] = useState<HeatmapData>();

  useEffect(() => {
    fetchWithToken(getAccessTokenSilently, "/utilization?customer").then(
      (resp: UtilizationForInstrument[]) => {
        const rowLabels = resp.map(
          (v) => `${cleanTenantName(v.tenant)} (${v.serialNumber})`
        );
        const data: HeatmapElement[][] = [];
        for (let i = 0; i < resp.length; i++) {
          data.push(
            resp[i].utilization.map((v) => {
              if (v < 0) return { val: NaN, label: "" };
              return { val: v * 100, label: (v * 100).toFixed(0) };
            })
          );
        }
        setHeatmapData({ rowLabels, data });
      }
    );
  }, [getAccessTokenSilently]);

  console.log("utlization", heatmapData);
  return (
    <div
      style={{
        height: "100%",
        marginLeft: 80,
        marginTop: 30,
      }}
    >
      {heatmapData && (
        <GenericHeatmap
          title="Instrument Utilization"
          data={heatmapData.data}
          rowlabels={heatmapData.rowLabels}
          minv={0}
          maxv={100}
          tooltipprecision={1}
          hidelegend={false}
          scale={2}
          nogap
          zeroAsWhite
          maxH="75%"
        />
      )}
    </div>
  );
};

export default ExecutiveUtilization;
