import React, {
  memo,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { SelectBox, Button } from "devextreme-react";
import { Run } from "../types";
import { LoadPanel } from "devextreme-react/load-panel";
import { useFetch } from "../../../hooks/useFetch";

import {
  Chart,
  CommonSeriesSettings,
  ConstantLine,
  Export,
  SeriesTemplate,
  Legend,
  ValueAxis,
  Point,
  Label,
  ArgumentAxis,
  Tooltip,
  ZoomAndPan,
  Annotation,
} from "devextreme-react/chart";
import { Box } from "@mui/material";

interface DataElement {
  cycle: number;
  read: string;
  percentError: number;
  percentQ30: number;
  percentQ40: number;
  averageQ: number;
  phixAlignment: number;
  phasing: number;
  prephasing: number;
  residualCh1: number;
  residualCh2: number;
  residualCh3: number;
  residualCh4: number;
  intensityCh1: number;
  intensityCh2: number;
  intensityCh3: number;
  intensityCh4: number;
  fwhmCh1: number;
  fwhmCh2: number;
  fwhmCh3: number;
  fwhmCh4: number;
  zScoreGreen: number;
  zScoreRed: number;
}

interface ValueDataElement {
  cycle: number;
  read: string;
  value: number;
}

type RunQualityProps = {
  run: Run;
};

const q30Range = [0, 100];
const avgQRange = [0, 50];
const errorRange = [0, 20];
const metricsLevels = ["Run Level", "Library Pool 1", "Library Pool 2"];

const calcMean = (vals) => {
  if (vals.length === 0) return 0;
  let sum = 0;
  for (let i = 0; i < vals.length; i++) sum += vals[i];
  return sum / vals.length;
};

const linearFit = (xv: number[], yv: number[]): [number, number] => {
  const N = xv.length;
  if (N === 0) return [NaN, NaN];
  const xmean = calcMean(xv);
  const ymean = calcMean(yv);

  let XY = 0;
  let XX = 0;

  for (let i = 0; i < N; i++) {
    XY += (xv[i] - xmean) * (yv[i] - ymean);
    XX += (xv[i] - xmean) * (xv[i] - xmean);
  }

  const m = XY / XX;
  const b = ymean - m * xmean;

  return [m, b];
};

const calculateTrendLine = (
  data: any,
  arg: string,
  read: string
): [{ x: number; y: number }, { x: number; y: number }, number] => {
  const xvals = [];
  const yvals = [];

  let mincycle = 1000;
  let maxcycle = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].read === read) {
      if (!data[i][arg]) continue; // skip nan (also 0, but oh well)
      const cycle = data[i].cycle;
      xvals.push(cycle);
      yvals.push(data[i][arg]);
      if (cycle < mincycle) mincycle = cycle;
      if (cycle > maxcycle) maxcycle = cycle;
    }
  }

  const [m, b] = linearFit(xvals, yvals);

  const vals: [{ x: number; y: number }, { x: number; y: number }, number] = [
    { x: mincycle, y: mincycle * m + b },
    { x: maxcycle, y: maxcycle * m + b },
    m,
  ];

  return vals;
};

const RunQuality = memo(({ run }: RunQualityProps) => {
  const chart = useRef();
  const [display, setDisplay] = useState<
    | "Phasing"
    | "Prephasing"
    | "Intensity"
    | "Residual"
    | "FWHM"
    | "ZScore"
    | "Error"
    | "Q30"
    | "Q40"
    | "AvgQ"
  >("Phasing");
  const [libraryPoolDisplay, setLibraryPoolDisplay] = useState<0 | 1 | 2>(0);
  const [avgLine, setAvgLine] = useState(null);
  const containerDiv = useRef();
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const [data, setData] = useState<DataElement[]>([]);
  const [intensityData, setIntensityData] = useState<ValueDataElement[]>([]);
  const [residualData, setResidualData] = useState<ValueDataElement[]>([]);
  const [fwhmData, setFWHMData] = useState<ValueDataElement[]>([]);
  const [zScoreData, setZScoreData] = useState<ValueDataElement[]>([]);
  const [annotations, setAnnotations] = useState({
    phasing: [],
    prephasing: [],
  });
  const [forceRefresh, setForceRefresh] = useState(false);

  const {
    data: rawdata,
    isLoading,
    refetch,
    isError,
    error,
  } = useFetch(
    `/run/${run?.runID}/quality/${libraryPoolDisplay}` +
      (forceRefresh ? "?forceRefresh" : ""),
    !!run?.runID
  );

  useEffect(() => {
    if (isError) {
      alert(
        "An error has occurred trying to fetch the data:" +
          JSON.stringify(error)
      );
      console.log(error);
    }
  }, [isError, error]);

  useEffect(() => {
    if (rawdata) {
      const tempdata = rawdata as DataElement[];
      const justReads = tempdata.map((x) => x.read);
      const tmp = [...Array.from(new Set<string>(justReads))];

      let { readOrder } = run;

      if (readOrder.indexOf(",") === -1 && readOrder.length === 8) {
        readOrder =
          readOrder.substring(0, 2) +
          "," +
          readOrder.substring(2, 4) +
          "," +
          readOrder.substring(4, 6) +
          "," +
          readOrder.substring(6);
      }
      const sortOrder = readOrder.split(",");
      let distinctReads = [];
      for (var j = 0; j < sortOrder.length; j++) {
        if (tmp.includes(sortOrder[j])) distinctReads.push(sortOrder[j]);
      }
      var maxCycleForRead = {};
      for (j = 0; j < distinctReads.length; j++) {
        maxCycleForRead[distinctReads[j]] = 0;
      }

      for (j = 0; j < tempdata.length; j++) {
        if (tempdata[j].cycle > maxCycleForRead[tempdata[j].read]) {
          maxCycleForRead[tempdata[j].read] = tempdata[j].cycle;
        }
      }
      var readCycleOffset = {};
      readCycleOffset[distinctReads[0]] = 0;
      var currentOffset = 0;
      for (j = 0; j < distinctReads.length; j++) {
        readCycleOffset[distinctReads[j]] = currentOffset;
        currentOffset += maxCycleForRead[distinctReads[j]];
      }

      for (var i = 0; i < tempdata.length; i++) {
        tempdata[i].cycle += readCycleOffset[tempdata[i].read];
      }

      let tmpIntensity: ValueDataElement[] = [];

      let tmpResidual: ValueDataElement[] = [];
      let tmpFWHM: ValueDataElement[] = [];
      let tmpZScore: ValueDataElement[] = [];

      for (i = 0; i < tempdata.length; i++) {
        if (isNaN(tempdata[i].cycle)) tempdata[i].cycle = i + 1; // to compensate for apparent bug with P1 data
        if (tempdata[i].percentError === -999) tempdata[i].percentError = NaN;
        if (tempdata[i].percentQ30 === -999) tempdata[i].percentQ30 = NaN;
        if (tempdata[i].percentQ40 <= 0) tempdata[i].percentQ40 = NaN;
        if (tempdata[i].averageQ < 0) tempdata[i].averageQ = NaN;
        if (tempdata[i].phasing === -999) tempdata[i].phasing = NaN;
        if (tempdata[i].prephasing === -999) tempdata[i].prephasing = NaN;
        if (tempdata[i].residualCh1 === -999) tempdata[i].residualCh1 = NaN;
        else tempdata[i].residualCh1 *= 100;
        if (tempdata[i].residualCh2 === -999) tempdata[i].residualCh2 = NaN;
        else tempdata[i].residualCh2 *= 100;
        if (tempdata[i].residualCh3 === -999) tempdata[i].residualCh3 = NaN;
        else tempdata[i].residualCh3 *= 100;
        if (tempdata[i].residualCh4 === -999) tempdata[i].residualCh4 = NaN;
        else tempdata[i].residualCh4 *= 100;
        if (tempdata[i].intensityCh1 < 0) tempdata[i].intensityCh1 = 0;
        if (tempdata[i].intensityCh2 < 0) tempdata[i].intensityCh2 = 0;
        if (tempdata[i].intensityCh3 < 0) tempdata[i].intensityCh3 = 0;
        if (tempdata[i].intensityCh4 < 0) tempdata[i].intensityCh4 = 0;

        tmpIntensity.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch1`,
          value: tempdata[i].intensityCh1,
        });
        tmpIntensity.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch2`,
          value: tempdata[i].intensityCh2,
        });
        tmpIntensity.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch3`,
          value: tempdata[i].intensityCh3,
        });
        tmpIntensity.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch4`,
          value: tempdata[i].intensityCh4,
        });

        tmpResidual.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch1`,
          value: tempdata[i].residualCh1,
        });
        tmpResidual.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch2`,
          value: tempdata[i].residualCh2,
        });
        tmpResidual.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch3`,
          value: tempdata[i].residualCh3,
        });
        tmpResidual.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch4`,
          value: tempdata[i].residualCh4,
        });

        tmpFWHM.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch1`,
          value: tempdata[i].fwhmCh1,
        });
        tmpFWHM.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch2`,
          value: tempdata[i].fwhmCh2,
        });
        tmpFWHM.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch3`,
          value: tempdata[i].fwhmCh3,
        });
        tmpFWHM.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Ch4`,
          value: tempdata[i].fwhmCh4,
        });

        tmpZScore.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Green`,
          value: tempdata[i].zScoreGreen,
        });

        tmpZScore.push({
          cycle: tempdata[i].cycle,
          read: `${tempdata[i].read} Red`,
          value: tempdata[i].zScoreRed,
        });
      }

      setIntensityData(tmpIntensity);
      setResidualData(tmpResidual);
      setZScoreData(tmpZScore);
      setFWHMData(tmpFWHM);

      const [phasing_pt1, phasing_pt2, phasing_mR1] = calculateTrendLine(
        tempdata,
        "phasing",
        "R1"
      );
      const [prephasing_pt1, prephasing_pt2, prephasing_mR1] =
        calculateTrendLine(tempdata, "prephasing", "R1");
      tempdata.push({
        cycle: phasing_pt1.x,
        phasing: phasing_pt1.y,
        read: "R1 Trend",
        percentError: NaN,
        percentQ30: NaN,
        percentQ40: NaN,
        averageQ: NaN,
        phixAlignment: NaN,
        prephasing: prephasing_pt1.y,
        residualCh1: NaN,
        residualCh2: NaN,
        residualCh3: NaN,
        residualCh4: NaN,
        intensityCh1: NaN,
        intensityCh2: NaN,
        intensityCh3: NaN,
        intensityCh4: NaN,
        fwhmCh1: NaN,
        fwhmCh2: NaN,
        fwhmCh3: NaN,
        fwhmCh4: NaN,
        zScoreGreen: NaN,
        zScoreRed: NaN,
      });
      tempdata.push({
        cycle: phasing_pt2.x,
        phasing: phasing_pt2.y,
        read: "R1 Trend",
        percentError: NaN,
        percentQ30: NaN,
        percentQ40: NaN,
        phixAlignment: NaN,
        averageQ: NaN,
        prephasing: prephasing_pt2.y,
        residualCh1: NaN,
        residualCh2: NaN,
        residualCh3: NaN,
        residualCh4: NaN,
        intensityCh1: NaN,
        intensityCh2: NaN,
        intensityCh3: NaN,
        intensityCh4: NaN,
        fwhmCh1: NaN,
        fwhmCh2: NaN,
        fwhmCh3: NaN,
        fwhmCh4: NaN,
        zScoreGreen: NaN,
        zScoreRed: NaN,
      });

      const [r2_phasing_pt1, r2_phasing_pt2, phasing_mR2] = calculateTrendLine(
        tempdata,
        "phasing",
        "R2"
      );
      const [r2_prephasing_pt1, r2_prephasing_pt2, prephasing_mR2] =
        calculateTrendLine(tempdata, "prephasing", "R2");
      tempdata.push({
        cycle: r2_phasing_pt1.x,
        phasing: r2_phasing_pt1.y,
        read: "R2 Trend",
        percentError: NaN,
        percentQ30: NaN,
        percentQ40: NaN,
        averageQ: NaN,
        phixAlignment: NaN,
        prephasing: r2_prephasing_pt1.y,
        residualCh1: NaN,
        residualCh2: NaN,
        residualCh3: NaN,
        residualCh4: NaN,
        intensityCh1: NaN,
        intensityCh2: NaN,
        intensityCh3: NaN,
        intensityCh4: NaN,
        fwhmCh1: NaN,
        fwhmCh2: NaN,
        fwhmCh3: NaN,
        fwhmCh4: NaN,
        zScoreGreen: NaN,
        zScoreRed: NaN,
      });
      tempdata.push({
        cycle: r2_phasing_pt2.x,
        phasing: r2_phasing_pt2.y,
        read: "R2 Trend",
        percentError: NaN,
        percentQ30: NaN,
        percentQ40: NaN,
        averageQ: NaN,
        phixAlignment: NaN,
        prephasing: r2_prephasing_pt2.y,
        residualCh1: NaN,
        residualCh2: NaN,
        residualCh3: NaN,
        residualCh4: NaN,
        intensityCh1: NaN,
        intensityCh2: NaN,
        intensityCh3: NaN,
        intensityCh4: NaN,
        fwhmCh1: NaN,
        fwhmCh2: NaN,
        fwhmCh3: NaN,
        fwhmCh4: NaN,
        zScoreGreen: NaN,
        zScoreRed: NaN,
      });

      setAnnotations({
        phasing: [
          {
            x: (prephasing_pt1.x + prephasing_pt2.x) / 2,
            read: "R1 Trend",
            m: phasing_mR1,
          },
          {
            x: (r2_prephasing_pt1.x + r2_prephasing_pt2.x) / 2,
            read: "R2 Trend",
            m: phasing_mR2,
          },
        ],
        prephasing: [
          {
            x: (prephasing_pt1.x + prephasing_pt2.x) / 2,
            read: "R1 Trend",
            m: prephasing_mR1,
          },
          {
            x: (r2_prephasing_pt1.x + r2_prephasing_pt2.x) / 2,
            read: "R2 Trend",
            m: prephasing_mR2,
          },
        ],
      });

      setData(tempdata);
    } else {
      setData([]);
    }
  }, [rawdata, run]);

  useEffect(() => {
    if (chart.current && data)
      (chart.current as any).instance.resetVisualRange();
  }, [chart, data]);

  useEffect(() => {
    chart.current && (chart.current as any).instance.render();
  }, []);

  useLayoutEffect(() => {
    let constLineColor = "gold";
    let constLineAvg = 0;
    let lines = [];

    if (
      data &&
      data.length > 0 &&
      ["Q40", "Q30", "AvgQ", "Error"].indexOf(display) >= 0
    ) {
      const allSeries = (chart.current as any).instance.getAllSeries();

      for (let i = 0; i < allSeries.length; i++) {
        const series = allSeries[i];
        // if (i > 3) series.hide();
        constLineColor = series.getColor();
        const pts = series.getAllPoints();
        let avg = 0.0;
        for (let i = 0; i < pts.length; i++) {
          avg = avg + pts[i].value;
        }
        constLineAvg = avg / pts.length;

        lines.push(
          <ConstantLine
            width={1}
            key={`constantLine${i}`}
            value={constLineAvg}
            color={constLineColor}
            dashStyle="dash"
          >
            <Label
              font={{ color: constLineColor }}
              text={constLineAvg.toPrecision(4)}
            />
          </ConstantLine>
        );
      }
    }
    setAvgLine(lines);
  }, [data, display]);

  const phixAlignmentRates = useMemo(() => {
    const phixR1 = data
      .filter((v) => v.read === "R1")
      .filter((v) => v.phixAlignment !== -999)
      .map((v) => v.phixAlignment);
    const phixR2 = data
      .filter((v) => v.read === "R2")
      .filter((v) => v.phixAlignment !== -999)
      .map((v) => v.phixAlignment);
    return [calcMean(phixR1), calcMean(phixR2)];
  }, [data]);

  const renderTooltip = useCallback((pointInfo) => {
    if (pointInfo && pointInfo.point && pointInfo.point.data) {
      const data = pointInfo.point.data;
      const label3 = "Cycle: " + Math.floor(data.cycle);
      return (
        <div>
          {pointInfo.value.toPrecision(4)}%
          <br />
          {label3}
        </div>
      );
    }
    return <div />;
  }, []);

  return (
    <div ref={containerDiv} style={{ marginTop: 40 }}>
      <LoadPanel
        position={{ of: "#chart" }}
        visible={isLoading}
        showIndicator={true}
        showPane={true}
      />

      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={[
                "Phasing",
                "Prephasing",
                "Intensity",
                "FWHM",
                "Residual",
                "ZScore",
                "Q30",
                "Q40",
                "AvgQ",
                "Error",
              ]}
              value={display}
              onValueChanged={(e) => {
                setDisplay(e.value);
              }}
            />
          </td>
        </tr>
      </table>
      <table>
        <tr>
          <td>Display:</td>
          <td>&nbsp;&nbsp;&nbsp;</td>
          <td>
            <SelectBox
              items={metricsLevels}
              value={metricsLevels[libraryPoolDisplay]}
              onValueChanged={(e) => {
                setLibraryPoolDisplay(
                  metricsLevels.indexOf(e.value) as 0 | 1 | 2
                );
              }}
            />
          </td>
        </tr>
      </table>
      {display === "Error" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            gap: 1,
          }}
        >
          <div> % PhiX Aligned: </div>
          <div>
            {" "}
            R1{" "}
            {phixAlignmentRates[0]
              ? phixAlignmentRates[0].toPrecision(4)
              : "--"}
            % | R2{" "}
            {phixAlignmentRates[1]
              ? phixAlignmentRates[1].toPrecision(4)
              : "--"}
            %{" "}
          </div>
        </Box>
      )}
      <Chart
        id="chart"
        ref={chart}
        height="70vh"
        onLegendClick={useCallback((e) => {
          const series = e.target;
          if (series.isVisible()) {
            series.hide();
          } else {
            series.show();
          }
        }, [])}
        dataSource={useMemo(() => {
          if (display === "Intensity") return intensityData;
          if (display === "Residual") return residualData;
          if (display === "FWHM") return fwhmData;
          if (display === "ZScore") return zScoreData;
          return data;
        }, [display, intensityData, residualData, data, fwhmData, zScoreData])}
      >
        <CommonSeriesSettings
          argumentField="cycle"
          valueField={useMemo(() => {
            if (display === "Q40" || display === "Q30" || display === "Error")
              return "percent" + display;
            if (display === "AvgQ") return "averageQ";
            if (["Residual", "Intensity", "ZScore", "FWHM"].includes(display))
              return "value";
            return display.toLowerCase();
          }, [display])}
          type="line"
        >
          <Point size={4} />
        </CommonSeriesSettings>
        <SeriesTemplate nameField="read" />
        <Tooltip enabled={true} contentRender={renderTooltip} />
        <ValueAxis
          title={display.includes("Intensity") ? display : display + " (%)"}
          wholeRange={
            display === "Q30" || display === "Q40"
              ? q30Range
              : display === "Error"
              ? errorRange
              : display === "AvgQ"
              ? avgQRange
              : null
          }
        >
          {avgLine}
        </ValueAxis>
        <ArgumentAxis title="Cycle" allowDecimals={true} />
        <Legend
          visible={true}
          position="outside"
          horizontalAlignment="center"
          verticalAlignment="bottom"
        />
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Export enabled={true} />

        {annotations[display.toLowerCase()] &&
          annotations[display.toLowerCase()].map((ann) => {
            return (
              <Annotation
                key={ann.read}
                argument={ann.x}
                type="text"
                paddingTopBottom={4}
                paddingLeftRight={10}
                offsetY={-50}
                text={`${ann.m.toPrecision(3)}%/cycle`}
                series={ann.read}
                color={"transparent"}
                font={{ color: "brown" }}
              />
            );
          })}
      </Chart>
      <Button
        style={{ marginTop: 5 }}
        text="Refresh from Snowflake"
        icon="refresh"
        onClick={() => {
          setForceRefresh(true);
          refetch();
        }}
      />
      {/* <Button
        style={{ marginTop: 5 }}
        text={isFullScreen ? "Restore" : "Full Screen"}
        icon="fullscreen"
        onClick={() => {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else {
            (containerDiv.current as any).requestFullscreen();
          }
          setIsFullScreen((x) => !x);
        }}
      /> */}
    </div>
  );
});

export default RunQuality;
