import React from "react";
import "./profile.scss";
import Form from "devextreme-react/form";
import { useAuth } from "../../contexts/auth";

const colCountByScreen = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

export default () => {
  const { user } = useAuth();

  if (user) user.org_name = user["https://elembio.io/organization_name"];

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Profile</h2>

      <div className={"content-block dx-card responsive-paddings"}>
        <div className={"form-avatar"}>
          <img alt={""} src={user.picture} />
        </div>
        <span>{"Lorem ipsum "}</span>
      </div>

      <div className={"content-block dx-card responsive-paddings"}>
        <Form
          id={"form"}
          defaultFormData={user}
          // onFieldDataChanged={(e) =>
          //   e.dataField === "Notes" && setNotes(e.value)
          // }
          labelLocation={"top"}
          colCountByScreen={colCountByScreen}
        />
      </div>
    </React.Fragment>
  );
};
