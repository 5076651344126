import { memo, useState, useEffect, useMemo, useCallback } from "react";

import { fetchWithToken } from "src/hooks/useFetch";
import { useAuth } from "src/contexts/auth";

import { GenericHeatmap } from "src/components/GenericHeatmap";
import { SelectBox } from "devextreme-react";
import PFChart from "./PassFailChart";

import { gradeMetricForRun } from "src/utils/run-grading";

// interface UtilizationForInstrument {
//   tenant: string;
//   serialNumber: string;
//   utilization: number[];
// }

// week 0
const startDate = new Date(2023, 0, 2, 18, 0, 0);

const getWeekString = (weeks) => {
  if (weeks === 1) return "Week";
  return `Week (${weeks} week avg)`;
};

export default memo(() => {
  const { getAccessTokenSilently, user } = useAuth();
  const [dispositions, setDispositions] = useState(null);
  const [totalData, setTotalData] = useState([]);
  const [last4WeeksSuccess, setLast4WeeksSuccess] = useState([]);
  const [sortBy, setSortBy] = useState("Usage");

  const [successData, setSuccessData] = useState([[]]);
  const [rowLabels, setRowLabels] = useState([]);

  const [instruments, setInstruments] = useState(null);
  const [runs, setRuns] = useState(null);
  const [tenantSelectBoxItems, setTenantSelectBoxItems] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState("All");
  const [weeksInBin, setWeeksInBin] = useState(1);
  const hasPermission = user && user["https://elembio.io/executive"];

  const getLabel = useCallback(
    (week) => {
      const d1 = new Date(startDate.getTime() + week * 7 * 24 * 60 * 60 * 1000);

      const d2 = new Date(
        d1.getTime() +
          7 * weeksInBin * 24 * 60 * 60 * 1000 +
          6 * 24 * 60 * 60 * 1000
      );

      return `${d1.getMonth() + 1}/${d1.getDate()} - ${
        d2.getMonth() + 1
      }/${d2.getDate()}`;
    },
    [weeksInBin]
  );

  const getSortedIndices = (arr) => {
    if (!arr || arr.length === 0) return [];
    // Create an array of indices [0, 1, 2, ...]
    const indices = Array.from(arr.keys());

    if (sortBy === "Name" || selectedTenant !== "All") return indices;
    // Sort the indices array based on the corresponding values in the input array
    indices.sort((a, b) => arr[a] - arr[b]);
    return indices;
  };

  const sortArrayByIndices = (array, indices) => {
    if (sortBy === "Name" || selectedTenant !== "All") return array;
    if (!indices || indices.length === 0) return indices;
    return indices.map((index) => array[index]);
  };

  useEffect(() => {
    fetchWithToken(getAccessTokenSilently, "/instrument?customer").then(
      (instr) =>
        setInstruments(instr.filter((v) => !v.tenantID.includes("uckerberg")))
    );

    fetchWithToken(getAccessTokenSilently, "/run?customer").then((r) =>
      setRuns(r.filter((v) => !v.tenantID.includes("uckerberg")))
    );

    fetchWithToken(getAccessTokenSilently, "/dispositions").then((resp) =>
      setDispositions(resp)
    );
  }, [getAccessTokenSilently]);

  const maxWeek = useMemo(() => {
    var dt = new Date();
    const days = Math.floor((dt - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.floor(days / 7);
    return weekNumber;
  }, []);

  useEffect(() => {
    if (!runs || !instruments || !dispositions || maxWeek === 0) return;
    const vals = runs;
    vals.sort((a, b) =>
      a.tenantID
        ?.replaceAll("ten_", "")
        .replaceAll("ten-", "")
        .localeCompare(
          b.tenantID?.replaceAll("ten_", "").replaceAll("ten-", "")
        )
    );
    const tenantMap = new Map();
    const numInstruments = new Map();

    const totals = [];

    for (let w = 0; w <= maxWeek; w++) {
      totals[w] = {
        runs: 0,
        passedAll: 0,
        didNotPassAll: 0,
        passedQ30: 0,
        didNotPassQ30: 0,
        passedPF: 0,
        didNotPassPF: 0,
        successes: 0,
        didNotFinish: 0,
        completed: 0,
        fails: 0,
        notDispositioned: 0,
      };
    }

    const selectBoxItems = ["All"];

    for (let i = 0; i < vals.length; i++) {
      const tenantName = vals[i].tenantID
        .replaceAll("ten_", "")
        .replaceAll("ten-", "")
        .replaceAll("-35dbeb9f-b8f5-4d9d-8423-a1209452f18c", "");
      if (!tenantMap.get(tenantName)) {
        tenantMap.set(tenantName, []);
        selectBoxItems.push(tenantName);
        const count = instruments.filter(
          (x) => x.tenantID === vals[i].tenantID
        ).length;
        numInstruments.set(tenantName, count > 0 ? count : 1);
      }
      const tenantEntry = tenantMap.get(tenantName);

      if (!vals[i].startTime) continue;

      const dt = new Date(vals[i].Date);

      const days = Math.floor((dt - startDate) / (24 * 60 * 60 * 1000));
      const weekNumber = Math.floor(days / 7);

      if (weekNumber < 0) continue;

      for (let w = weekNumber; w < weekNumber + weeksInBin; w++)
        if (w <= maxWeek)
          if (!tenantEntry[w])
            tenantEntry[w] = {
              successes: 0,
              failures: 0,
              optimizations: 0,
              notDispositioned: 0,
              runs: 0,
            };

      dt.setHours(dt.getHours() + 48);

      const isOld = new Date().getTime() > dt.getTime();
      const runCompleted =
        ["Finished", "Failed", "RecipeUpload_Completed"].includes(
          vals[i].lastStatus
        ) || isOld;

      const disp = dispositions.find((v) => v.id === vals[i].dispositionID);

      if (runCompleted) {
        const partNumber = vals[i].reagentPartNumber;

        const passed =
          gradeMetricForRun("q30", vals[i].q30, partNumber) === "green" &&
          gradeMetricForRun("poloniesPF", vals[i].poloniesPF, partNumber) ===
            "green" &&
          gradeMetricForRun("runTime", vals[i].runTime, partNumber) !== "red" &&
          gradeMetricForRun(
            "indexAssignmentPercent",
            vals[i].indexAssignmentPercent,
            partNumber
          ) !== "red";
        vals[i].passedAllMetrics = passed ? "YES" : "NO";

        if (selectedTenant === "All" || tenantName === selectedTenant) {
          for (let w = weekNumber; w < weekNumber + weeksInBin; w++)
            if (w <= maxWeek) {
              if (disp.color === "green") {
                totals[w].successes++;
              }
              if (disp.color !== "gray") {
                totals[w].runs++;
                if (vals[i].q30 >= 0.9) totals[w].passedQ30++;
                if (vals[i].poloniesPF >= 800) totals[w].passedPF++;
                if (
                  vals[i].lastStatus !== "Finished" &&
                  vals[i].lastStatus !== "RecipeUpload_Completed" &&
                  vals[i].lastStatus !== "WashFinished"
                ) {
                  totals[w].didNotFinish++;
                }
                if (vals[i].passedAllMetrics === "YES") totals[w].passedAll++;
              }
            }
        }
      }

      if (disp) {
        for (let w = weekNumber; w < weekNumber + weeksInBin; w++)
          if (w >= 0 && w <= maxWeek) {
            tenantEntry[w].runs++;
            if (disp.color === "green") {
              tenantEntry[w].successes++;
            } else if (disp.color === "red") {
              tenantEntry[w].failures++;
            } else if (disp.color === "yellow") {
              tenantEntry[w].optimizations++;
            } else {
              tenantEntry[w].notDispositioned++;
              totals[w].notDispositioned++;
            }
          }
      }
    }

    const tableEntries = [];
    const tenants = [];
    const heatmapSuccessData = [];
    const sumRuns = [];
    tenantMap.forEach((value, key) => {
      tableEntries.push({ name: key, ...value });
      tenants.push(key);
      const arraySuccess = [];
      let sum = 0;
      for (let i = 0; i <= maxWeek; i++) {
        if (value[i]) {
          if (i >= maxWeek - 8) sum -= value[i].runs;
          arraySuccess.push({
            val:
              (100 * value[i].successes) /
              (value[i].runs - value[i].notDispositioned),
            success: value[i].successes,
            total: value[i].runs - value[i].notDispositioned,
            label: `${value[i].successes}/${
              value[i].runs - value[i].notDispositioned
            }`,
          });
        } else {
          arraySuccess.push({ val: NaN, label: "", success: 0, total: 0 });
        }
      }
      sumRuns.push(sum);

      heatmapSuccessData.push(arraySuccess);
    });

    for (let w = 0; w <= maxWeek; w++) {
      totals[w].week = w;
      totals[w].fails = totals[w].runs - totals[w].successes;
      totals[w].completed = totals[w].runs - totals[w].didNotFinish;
      totals[w].didNotPassQ30 = totals[w].runs - totals[w].passedQ30;
      totals[w].didNotPassPF = totals[w].runs - totals[w].passedPF;
      totals[w].didNotPassAll = totals[w].runs - totals[w].passedAll;
    }

    setLast4WeeksSuccess(sumRuns);
    setTotalData(totals);
    setRowLabels(tenants);
    setSuccessData(heatmapSuccessData);
    setTenantSelectBoxItems(selectBoxItems);
  }, [instruments, runs, dispositions, selectedTenant, maxWeek, weeksInBin]);

  if (hasPermission === null) {
    return <div>Checking permissions...</div>;
  }
  if (hasPermission === false) {
    return <div>You do not have permission</div>;
  }

  const indexOrder2 = getSortedIndices(last4WeeksSuccess);

  // The latest datagrid implementation with embedded refresh toolbar icon
  return (
    <div style={{ paddingLeft: 80, background: "white" }}>
      <div style={{ marginLeft: 30, paddingTop: 50, display: "flex" }}>
        <table>
          <tr>
            <td># Weeks in Bin</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>
              <SelectBox
                items={[1, 2, 4, 12, 36]}
                defaultValue={1}
                onValueChanged={(e) => {
                  setWeeksInBin(e.value);
                }}
              />
            </td>
          </tr>
        </table>

        <table style={{ marginLeft: 40 }}>
          <tr>
            <td>Customer Filter:</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>
              <SelectBox
                items={tenantSelectBoxItems}
                defaultValue="All"
                onValueChanged={(e) => {
                  setSelectedTenant(e.value);
                }}
              />
            </td>
          </tr>
        </table>

        <table style={{ marginLeft: 40 }}>
          <tr>
            <td>Sort by:</td>
            <td>&nbsp;&nbsp;&nbsp;</td>
            <td>
              <SelectBox
                items={["Usage", "Name"]}
                defaultValue="Usage"
                onValueChanged={(e) => {
                  setSortBy(e.value);
                }}
              />
            </td>
          </tr>
        </table>
      </div>

      <div style={{ marginLeft: 80, marginTop: 30, marginBottom: 30 }}>
        <GenericHeatmap
          title={`Run Success by ${getWeekString(weeksInBin)}`}
          data={sortArrayByIndices(successData, indexOrder2).filter(
            (v, i) =>
              selectedTenant === "All" || rowLabels[i].includes(selectedTenant)
          )}
          rowlabels={sortArrayByIndices(rowLabels, indexOrder2).filter(
            (v) => selectedTenant === "All" || v.includes(selectedTenant)
          )}
          minv={0}
          maxv={100}
          tooltipprecision={1}
          hidelegend={false}
          scale={2}
          nogap
          redGreenColormap
        />
      </div>

      <div style={{ width: "90%", overflow: "hidden" }}>
        <PFChart
          totalData={totalData}
          title="Run Success"
          failedField="fails"
          successField="successes"
          argAxisLabel={getWeekString(weeksInBin)}
        />
        <PFChart
          totalData={totalData}
          title="Runs Meeting Full Spec"
          failedField="didNotPassAll"
          successField="passedAll"
          argAxisLabel={getWeekString(weeksInBin)}
        />
        <PFChart
          totalData={totalData}
          title="Runs Completed Successfully"
          failedField="didNotFinish"
          successField="completed"
          argAxisLabel={getWeekString(weeksInBin)}
        />
        <PFChart
          totalData={totalData}
          title="Runs Meeting Quality Specification"
          failedField="didNotPassQ30"
          successField="passedQ30"
          argAxisLabel={getWeekString(weeksInBin)}
        />

        <PFChart
          totalData={totalData}
          title="Runs Meeting PF Specification"
          failedField="didNotPassPF"
          successField="passedPF"
          argAxisLabel={getWeekString(weeksInBin)}
        />
      </div>

      <hr />

      {/* <div
        style={{
          width: "80%",
          fontWeight: 500,
          fontSize: "2em",
          color: "#555555",
          paddingTop: 40,
          marginBottom: -30,
        }}
      >
        Run Success and Utilization
      </div>

      <div style={{ width: "90%", overflow: "hidden" }}>
        <Chart dataSource={aggregateData}>
          <CommonSeriesSettings
            argumentField="label"
            type="bar"
            barPadding={0}
          />
          <Series valueField="success" name="Success" color="rgb(78,115,190)" />
          <Series
            valueField="utilization"
            name="Utilization"
            color="rgb(223,161,68)"
          />
          <ValueAxis
            title="%"
            visualRangeUpdateMode="keep"
            visualRange={[0, 100]}
          >
            <Grid visible={true} />
          </ValueAxis>
          <ArgumentAxis>
            <Label wordWrap="none" overlappingBehavior="stagger" />
          </ArgumentAxis>
          <Legend
            verticalAlignment="bottom"
            horizontalAlignment="center"
          ></Legend>
          <Export enabled />
          <Tooltip enabled>
            <Format type="fixedPoint" precision={1} />
          </Tooltip>
        </Chart>
      </div> */}
    </div>
  );
});
