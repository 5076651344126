import { FC, memo, useState, useEffect } from "react";
import { CheckBox } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";

import { FlowcellChart } from "../../../components";
import { ColorRangeBar } from "../../../components";
import { decompressFloat32Array } from "src/utils/decompress";
import { ProdStatsType, TilesDataType } from "./run-container";

interface PolonyDensityProps {
  data: ProdStatsType;
  isLoading: boolean;
}
const sum = (vec: number[]): number => {
  return vec.reduce((prev, cur) => prev + cur, 0);
};

const PolonyDensity: FC<PolonyDensityProps> = memo(
  ({ data, isLoading }: PolonyDensityProps) => {
    const [range, setRange] = useState({ min: 0, max: 100 });
    const [defaultRange, setDefaultRange] = useState(null);
    const [showPF, setShowPF] = useState(false);
    const [fixScale, setFixScale] = useState(false);

    const [polonyData, setPolonyData] = useState([]);
    const [tileLabels, setTileLabels] = useState([]);
    const [rawPolonyCount, setRawPolonyCount] = useState(-1);
    const [pfPolonyCount, setPFPolonyCount] = useState(-1);
    const [lanePolonyCount, setLanePolonyCount] = useState<number[]>([]);
    const [lanePFCounts, setLanePFCount] = useState<number[]>([]);

    useEffect(() => {
      if (!data) return;

      const pst = data as ProdStatsType;

      const drt = pst?.tiles as TilesDataType;
      if (drt && drt.rawPolonyCounts) {
        setLanePFCount(pst.pfCountsInMByLane);
        setLanePolonyCount(pst.polonyCountsInMByLane);
        setPFPolonyCount(sum(pst.pfCountsInMByLane));
        setRawPolonyCount(sum(pst.polonyCountsInMByLane));

        setTileLabels(drt.tileLabels);

        const str = showPF ? drt.pfPolonyCounts : drt.rawPolonyCounts;

        let vals = decompressFloat32Array(str);

        let minv = 999999;
        let maxv = -999999;

        if (!vals) {
          vals = [];
          for (let i = 0; i < drt.tileLabels.length; i++) vals.push(0);
        }

        for (let i = 0; i < drt.tileLabels.length; i++) {
          const value = vals[i];
          if (value < minv) minv = value;
          if (value > maxv) maxv = value;
        }
        if (minv > maxv) {
          minv = 0;
          maxv = 100;
        } else if (minv === maxv) {
          minv = minv * 0.9;
          maxv = maxv * 1.1;
        }
        setPolonyData(vals);
        if (!fixScale) {
          setRange({ min: minv, max: maxv });
          setDefaultRange({ min: minv, max: maxv });
        }
      }
    }, [data, showPF, fixScale]);

    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginTop: 30,
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: "#parentDiv" }}
            visible={isLoading}
            showIndicator={true}
            shading={true}
            // showPane={true}
          />

          <br />

          {polonyData && rawPolonyCount >= 0 && (
            <div style={{ display: "flex", gap: 40, marginLeft: 30 }}>
              <div>
                <strong>Raw Polony Count</strong>
                <table>
                  {lanePolonyCount.map((v, i) => (
                    <tr key={i}>
                      <td>L{i + 1}</td>
                      <td>{lanePolonyCount[i].toFixed(1)}M</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Total</td>
                    <td>{rawPolonyCount.toFixed(1) + "M"}</td>
                  </tr>
                </table>
              </div>

              <div>
                <strong>PF Polony Count</strong>
                <table>
                  {lanePolonyCount.map((v, i) => (
                    <tr key={i}>
                      <td>L{i + 1}</td>
                      <td>{lanePFCounts[i].toFixed(1)}M</td>
                    </tr>
                  ))}
                  <tr>
                    <td>Total</td>
                    <td>{pfPolonyCount.toFixed(1) + "M"}</td>
                  </tr>
                </table>
              </div>
            </div>
          )}

          <div
            style={{
              textAlign: "center",
              width: "400px",
              paddingRight: "100px",
              marginTop: "30px",
            }}
          >
            <strong>Polony Density (k/mm2)</strong>
          </div>
          <div
            id="parentDiv"
            style={{
              marginTop: "5px",
              display: "flex",
              width: "400px",
              height: "500px",
            }}
          >
            {polonyData && (
              <>
                <div style={{ width: "160px", height: "500px" }}>
                  <FlowcellChart
                    data={polonyData}
                    labels={tileLabels}
                    minv={range.min}
                    maxv={range.max}
                    title="S1"
                    tooltipprecision={0}
                    hidelegend
                  />
                </div>{" "}
                <div style={{ width: "160px", height: "500px" }}>
                  <FlowcellChart
                    data={polonyData}
                    labels={tileLabels}
                    minv={range.min}
                    maxv={range.max}
                    title="S2"
                    tooltipprecision={0}
                    hidelegend
                  />
                </div>
                <div
                  style={{
                    width: "100px",
                    display: defaultRange ? "" : "none",
                  }}
                >
                  <ColorRangeBar
                    minv={defaultRange?.min}
                    maxv={defaultRange?.max}
                    rangeChanged={(r) => setRange(r)}
                  />
                </div>
              </>
            )}
          </div>

          <CheckBox
            text="PF"
            style={{ marginLeft: "80px" }}
            value={showPF}
            onValueChanged={(e) => {
              setShowPF(e.value);
            }}
          />
          <CheckBox
            text="Fix Scale"
            style={{ marginLeft: "40px" }}
            value={fixScale}
            onValueChanged={(e) => {
              setFixScale(e.value);
            }}
          />
        </div>
      </div>
    );
  }
);

export default PolonyDensity;
