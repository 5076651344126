import { useRef, memo, useMemo, useCallback, useState, useEffect } from "react";
import { useAuth } from "src/contexts/auth";
import Map from 'devextreme-react/map';
import { fetchWithToken, endpointPath, myFetch } from "src/hooks/useFetch";
import { Box, Typography } from "@mui/material";


const Legend = () => {

  const [idle, setIdle] = useState("");
  const [c2x75, setC2x75] = useState("");
  const [c2x150, setC2x150] = useState("");
  const [c2x300, setC2x300] = useState("");

  useEffect(() => {
    myFetch(endpointPath(`/iconForMap?size=${2}&n150=${0}&n300=${0}&n600=${0}`)).then(v=>setIdle(v))
    myFetch(endpointPath(`/iconForMap?size=${6}&n150=${1}&n300=${0}&n600=${0}`)).then(v=>setC2x75(v))
    myFetch(endpointPath(`/iconForMap?size=${6}&n150=${0}&n300=${1}&n600=${0}`)).then(v=>setC2x150(v))
    myFetch(endpointPath(`/iconForMap?size=${6}&n150=${0}&n300=${0}&n600=${1}`)).then(v=>setC2x300(v))
  },[]);

  return (
    <Box position="absolute" top={20} left={20} sx={{backgroundColor: '#9999ffaa', px:1}} display="flex" flexDirection="column">
      <Box display="flex" gap="10px">
      <div dangerouslySetInnerHTML={{ __html: idle }} style={{marginTop:'6px', paddingLeft: '2px', width: '20px'}}/>
        <Typography>Idle</Typography>
      </Box>

      <Box display="flex" gap="10px">
      <div dangerouslySetInnerHTML={{ __html: c2x75 }} style={{marginTop:'3px', width: '20px'}}/>
        <Typography>2x75</Typography>
      </Box>

      <Box display="flex" gap="10px">
      <div dangerouslySetInnerHTML={{ __html: c2x150 }} style={{marginTop:'3px', width: '20px'}}/>
        <Typography>2x150</Typography>
      </Box>

      <Box display="flex" gap="10px">
      <div dangerouslySetInnerHTML={{ __html: c2x300 }} style={{marginTop:'3px', width: '20px'}}/>
        <Typography>2x300</Typography>
      </Box>
    </Box>
  )
}

export default memo((props) => {
  const { getAccessTokenSilently } = useAuth();
  const [locations, setLocations] = useState([]);


  useEffect(() => {
    const loadData = async () => {
      const data = await fetchWithToken(
        getAccessTokenSilently,
        "/mapData"
      );
      setLocations(data.map(v => {
        const r = Math.round(Math.sqrt(v.numRuns) * 4) + 2;
        const url = endpointPath(`/iconForMap?size=${r}&n150=${v.n150}&n300=${v.n300}&n600=${v.n600}`);
        return (
          {
            location: `${v.lat}, ${v.lon}`,
            iconSrc: url,
            tooltip: {
              text: v.tenant,
            }
          })
      }
      ))
    }
    loadData();
  }, [])


  // const { getAccessTokenSilently, fetchWithToken } = useAuth();
  // const [dispositions, setDispositions] = useState([]);

  // useEffect(() => {
  //   fetchWithToken(getAccessTokenSilently, "/dispositions").then((resp) =>
  //     setDispositions(resp)
  //   );
  // }, [getAccessTokenSilently]);


  return (
    <>
      <div style={{ display: "flex", flexDirection: 'column', height: "100%", background: 'white' }}>
        <Map
          defaultCenter="40.7061, -73.9969"
          defaultZoom={3}
          height="calc(100vh - 20px)"
          width="100%"
          // provider="bing"
          apiKey="AIzaSyBsbjyww1pLMBNpntQJal1Grq7Tp2Z05pE"
          type="roadmap"
          markers={locations}
        // markerIconSrc= "http://localhost:4123/api/iconForMap?size=5" // "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png" // "https://www.elementbiosciences.com/uploads/home/hero/home-hero-on-v6.png" // "https://js.devexpress.com/Demos/WidgetsGallery/JSDemos/images/maps/map-marker.png"
        // apiKey="AiG1c-Al3Uj27H4GsV5Ey8RU-soLkerlOtJ6PlF4IAh6fqLyv9tLo1nCDGIzsQHn" // "Av18NtQIlTqzZ4wLJYjcuC6Fm_hP9BIoTCG1iIBMV9rsQGph9SG4lrii10jC2bGS" // 
        >
        </Map>

        <Legend />
      </div>
    </>
  );
});
